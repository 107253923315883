import React, { Component, Fragment, useCallback, useEffect, useRef, useState, createContext, useContext } from 'react';
import { Container, Row, Col, Nav, Navbar, NavItem, NavLink, Modal, ModalBody } from 'reactstrap';
import _ from 'lodash';
import $ from 'jquery';
import cx from 'classnames';

/***
import './lib/font-awesome/css/font-awesome.min.css';
import './lib/animate/animate.min.css';
import './lib/ionicons/css/ionicons.min.css';
import './lib/owlcarousel/assets/owl.carousel.min.css';
***/

import './css/common.css';
import './css/header.css';
import './css/lightmode.css';
import './css/others.css';
import './css/style.scss';

import { Request, usePrevious, useWindowSize } from 'utils';


const ThemeContext = createContext('light');


function Home(props) {
  let [isSectionBVisible, setIsSectionBVisible] = useState(false);
  let [isSectionCVisible, setIsSectionCVisible] = useState(false);
  let [isSectionDVisible, setIsSectionDVisible] = useState(false);
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 300) {
          setVisible(true);
      } else if (scrolled <= 300) {
          setVisible(false);
      }
  };

  const scrollToTop = () => {
      window.scrollTo({
          top: 0,
          behavior: "smooth"
      });
  };

  window.addEventListener("scroll", toggleVisible);

  let last_scroll_position = useRef(window.scrollY);

  const [isValidPeriod, setIsValidPeriod] = useState(false);

  useEffect(() => {
      window.scrollTo(0, 0);

      Request.post('getIpAdminDetailsForSelfReg',{}).then((res)=>{
          let ress = res.meta.data
          setIsValidPeriod(ress?.is_valid_period)
      })

  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [width, height] = useWindowSize();


  const isInViewport = function (ref) {
    var top_of_element = $(ref).offset().top;
    var bottom_of_element = $(ref).offset().top + $(ref).outerHeight();
    var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
    var top_of_screen = $(window).scrollTop();

    if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)) {
      return true;
    } else {
      return false;
    }
  }

  const slideUpAnimation = function (ref, duration = 1500) {
    $(ref).css({
      "margin-top": "150px",
      "opacity": "0"
    }).animate({
      "margin-top": "0px",
      "opacity": "1"
    }, duration);
  }

  const handleScroll = useCallback(() => {

    if (last_scroll_position.current > window.scrollY) return last_scroll_position.current = window.scrollY
    last_scroll_position.current = window.scrollY

    if (isInViewport(".sectionB-anim")) {
      if (!isSectionBVisible) {
        setIsSectionBVisible(true);
        slideUpAnimation(".sectionB-anim")
      }
    } else {
      if (isSectionBVisible) {
        setIsSectionBVisible(false);
      }
    }

    // if (isInViewport(".sectionD-anim")) {
    //   if (!isSectionDVisible) {
    //     setIsSectionDVisible(true);
    //     slideUpAnimation(".sectionD-anim")
    //   }
    // } else {
    //   if (isSectionDVisible) {
    //     setIsSectionDVisible(false);
    //   }
    // }

    if (isInViewport(".sectionF-anim")) {
      if (!isSectionCVisible) {
        setIsSectionCVisible(true);
        slideUpAnimation(".sectionF-anim")
      }
    } else {
      if (isSectionCVisible) {
        setIsSectionCVisible(false);
      }
    }

  }, [last_scroll_position.current]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => { window.removeEventListener('scroll', handleScroll); };

  }, [handleScroll]);

  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);
  const toggleMobileMenu = (isOpen) => {
    setIsOpenMobileMenu(!isOpen);
  };

  return (
    <ThemeContext.Provider value={props.theme == 1 ? "dark" : "light"}>
      <div className={props.theme == 1 ? "theme-dark" : "theme-light"}>
        <header className="header">
          <nav className='navbar'>
            <li>
            <a href="#sectionA"><img className='nav-logo' src={props.theme == 1 ? "/assets/images/index/logo-dark.png" : "/assets/images/index/logo-light.png"} alt="" height={50} /></a>
            </li>
            <div className='d-flex'>
              <ul className={cx("nav-menu mb-0", { 'active': isOpenMobileMenu })}>
                <li className="nav-item">
                  <a href="#sectionD" onClick={() => toggleMobileMenu(isOpenMobileMenu)} className="nav-link">About us</a>
                </li>
                <li className="nav-item ml-0 mr-2">
                  <a href="#sectionB" onClick={() => toggleMobileMenu(isOpenMobileMenu)} className="nav-link">Features</a>
                </li>
              </ul>
              <div className="nav-buttons" style={{ "display": "flex", "alignItems": "center" }}>
              <span className='toggleIcon' id="toggleTheme" style={props.theme == 1 ? { backgroundColor: '#000000' } : { backgroundColor: '#ffffff' }}>
                <span className="material-icons" onClick={() => props.toggleTheme(0)} style={props.theme == 1 ? { color: '#ffffff' } : { backgroundColor: '#488CCB', color: '#ffffff' }}>light_mode</span>
                <span className="material-icons" onClick={() => props.toggleTheme(1)} style={props.theme == 1 ? { backgroundColor: '#488CCB', color: '#ffffff' } : {}}>dark_mode</span>
              </span>
                {width > 950 ? (<>
                  <span style={{ "width": "10px" }}></span>
                  <button className="login-btn" onClick={(e) => { e.preventDefault(); props.modalToggle(); }}>Login</button>
                  {isValidPeriod ? <>
                    <button className="free-trail-btn" onClick={(e)=>{ e.preventDefault(); props.modalToggle(); props.setActiveTabFn(4); }}>Self Registration</button>
                    <span style={{"width": "10px"}}></span>
                  </> :
                  <button className="free-trail-btn" onClick={(e)=>{ e.preventDefault(); props.modalToggle(); props.setActiveTabFn(2); }}>Start free trial</button>
                  }
                  </>) : null}
              </div>
              <div onClick={() => toggleMobileMenu(isOpenMobileMenu)} className={cx("hamburger home", { "active": isOpenMobileMenu })}>
                <span className="bar"></span>
                <span className="bar"></span>
                <span className="bar"></span>
              </div>
            </div>
          </nav>
        </header>
        <span className='toggleIcon' id="toggleTheme" style={props.theme == 1 ? { backgroundColor: '#000000' } : { backgroundColor: '#ffffff' }}>
          <span className="material-icons" onClick={() => props.toggleTheme(0)} style={props.theme == 1 ? { color: '#ffffff' } : { backgroundColor: '#488CCB', color: '#ffffff' }}>light_mode</span>
          <span className="material-icons" onClick={() => props.toggleTheme(1)} style={props.theme == 1 ? { backgroundColor: '#488CCB', color: '#ffffff' } : {}}>dark_mode</span>
        </span>
        <div id="sectionA"><SectionA {...props} screenWidth={width} /></div>
        <div id="sectionB"><SectionB /></div>
        <div id="sectionC"><SectionC /></div>
        <div id="sectionD"><SectionD /></div>
        <div id="footer1"><Footer /></div>

        <LoginPopupContent />
        <div className='upArrow' style={{ display: visible ? "inline" : "none" }}>
          <div className='pointer' onClick={scrollToTop} style={{backgroundColor: '#488CCB' }}>
            <i className="fa fa-arrow-up" style={{ color: '#ffffff' }}></i>
          </div>
        </div>
      </div>
    </ThemeContext.Provider>
  );
}

export default Home;

function LoginPopupContent() {
  return (
    <div className="modal fade" id="loginModal" tabIndex="-1" role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-xl modal-dialog-centered" role="document" style={{ "width": "70vw" }}>
        <div className="modal-content row" style={{ "height": "80vh", "borderRadius": "30px", "backgroundColor": "#061420", "padding": "0px" }}>
          <div className="col-md-6 col-sm-12 col-12 bg_image" style={{ "height": "100%" }}>
            <img src="/assets/images/login_bg.png" style={{ "height": "100%", "width": "100%", "objectFit": "cover" }} alt="" />
          </div>
          <div className="col-md-6 col-sm-12 col-12" style={{ "height": "100%" }}>
            <div className="flex-col-center text-theme" style={{ "color": "white", "height": "100%", "alignItems": "flex-start", "gap": "7px", "padding": "30px 70px" }}>
              <div style={{ "fontSize": "24px", "fontWeight": "600" }}>Login</div>
              <div style={{ "height": "1px", "backgroundColor": "white", "width": "100%", "margin": "7px 0px" }}></div>
              <div style={{ "height": "20px" }}></div>
              <div style={{ "fontSize": "16px", "fontWeight": "300" }}>Email Address</div>
              <input type="text" required placeholder="Enter Email Address" className="inputBox" />
              <div style={{ "height": "8px" }}></div>
              <div style={{ "fontSize": "16px", "fontWeight": "300" }}>Password</div>
              <input type="text" required placeholder="Enter Password" className="inputBox" />
              <div style={{ "height": "20px" }}> </div>
              <div className="ip" style={{ "textAlign": "center", "width": "100%", "fontWeight": "500", "color": "rgb(212, 212, 212)" }}>Your IP address is 192.168.9.1</div>
              <div style={{ "height": "20px" }}> </div>
              <button className="loginBtn">Login</button>
              <div style={{ "color": "#61C7C1", "fontWeight": "500", "textAlign": "center", "width": "100%" }}>
                Forgot your Password?
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>);
}

function SectionA(props) {

  const textRef = useRef(null);

  useEffect(() => {

  }, []);

  return (
    <div style={{ minHeight: '100vh' }}>
      <div className='body' style={{ backgroundImage: 'url(/assets/images/index/banner1.png)', backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom', paddingTop: '0', paddingBottom: '0px' }}>
        <div className="sectionA" style={{ justifyContent: 'flex-start', paddingTop: '120px' }}>
          <div className="row pt-5">
            <div className="col-md-12">
              <div className='banner-text'>
                <h1 className="titleText text-theme text-center">
                  Your Gateway to Comprehensive <br className='d-none d-sm-block' />information on <span style={{ "color": "#488CCB" }}>Road Projects</span> <br className='d-none d-sm-block' />across PPP, HAM & EPC </h1>
              </div>
            </div>
          </div>
        </div>
        {/* <div id="sectionD"><SectionD /></div> */}
      </div>
      {/* <div className="">
        <img className='banner-img' key={props.theme} src={props.theme == 1 ? "/assets/images/index/banner.png" : "/assets/images/index/banner.png"} alt="" />
      </div> */}
    </div>
  );
}

function SectionB() {
  return (
    <div className="body" style={{ paddingTop: '100px' }}>
      <div className="sectionB-anim">
        <div className="x-card">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div style={{ "display": "flex", "flexDirection": "column", "justifyContent": "center", "gap": "15px" }}>
                <div className='text-theme' style={{ "fontSize": "25px", fontWeight: '600' }}>Unlock the Potential of Roads India</div>
                <p className='m-0 text-theme'>India’s road infrastructure is the backbone of its economy, facilitating trade, connectivity, & access to services across urban and rural areas. In this context, having comprehensive, accurate, and timely data & information on road projects is crucial for several reasons :</p>
                <ul className='bullet-list'>
                  <li className='text-theme'>Strategic Planning & Policy Formulation</li>
                  <li className='text-theme'>Improved Decision-Making for Stakeholders</li>
                  <li className='text-theme'>Financial Performance & Risk Assessment</li>
                  <li className='text-theme'>Project Transparency & Accountability</li>
                  <li className='text-theme'>Market Intelligence & Competitive Analysis</li>
                  <li className='text-theme'>Efficient Resource Allocation</li>
                  <li className='text-theme'>Boosting Public-Private Partnerships (PPPs)</li>
                  <li className='text-theme'>Supporting Research & Innovation</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 text-center">
              <span className='d-block d-lg-none' style={{ height: '20px' }}></span>
              <img src={`${process.env.PUBLIC_URL}assets/images/index/automation.png`} className='auto-img' width="100%" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function SectionC() {
  return (<div className="body" style={{ "textAlign": "center", "paddingTop": "100px", "paddingBottom": "50px" }}>
    <div className="row sectionF-anim">
      <div className="col-sm-6 col-md-4 text-theme">
        <h2 className="text-theme feature-title">
          What Roads India Offers?
        </h2>
        <span className='d-block h30'></span>
        <div className='features'>
          <img height="25px" src="/assets/images/index/offer1.png" alt="" />
          <span className='features-subtitle text-theme'>Comprehensive Project Information:</span>
          <span className='feature-desc'>
            Detailed milestone based data & insights from project initiation to completion.
          </span>
        </div>
        <div className='features'>
          <img height="25px" src="/assets/images/index/offer2.png" alt="" />
          <span className='features-subtitle text-theme'>Performance Tracking:</span>
          <span className='feature-desc'>
            Monitor SPV financial performance throughout the project life cycle.
          </span>
        </div>
        <div className='features'>
          <img height="25px" src="/assets/images/index/offer3.png" alt="" />
          <span className='features-subtitle text-theme'>Interactive Query:</span>
          <span className='feature-desc'>
            Access data in various slices tailored to specific needs.
          </span>
        </div>
        <div className='features'>
          <img height="25px" src="/assets/images/index/offer4.png" alt="" />
          <span className='features-subtitle text-theme'>Geo-visual presentation:</span>
          <span className='feature-desc'>
            View the road projects Geo-visually
          </span>
        </div>
      </div>

      <div className="col-sm-6 col-md-5 text-theme">
        <h2 className="text-theme feature-title">
          Who Benefits from Roads India?
        </h2>
        <span className='d-block h30'></span>
        <div className='features'>
          <img height="25px" src="/assets/images/index/feature1.png" alt="" />
          <span className='features-subtitle text-theme'>Government Departments:</span>
          <span className='feature-desc'>
            Enhance project oversight and decision-making.
          </span>
        </div>
        <div className='features'>
          <img height="25px" src="/assets/images/index/feature2.png" alt="" />
          <span className='features-subtitle text-theme'>Developers & Contractors:</span>
          <span className='feature-desc'>
            Stay competitive with current market information.
          </span>
        </div>
        <div className='features'>
          <img height="25px" src="/assets/images/index/feature3.png" alt="" />
          <span className='features-subtitle text-theme'>Investors & Lenders:</span>
          <span className='feature-desc'>
            Informed decision-making with reliable financial & physical milestone achieved based data-sets.
          </span>
        </div>
        <div className='features'>
          <img height="25px" src="/assets/images/index/feature4.png" alt="" />
          <span className='features-subtitle text-theme'>Consultants & Analysts:</span>
          <span className='feature-desc'>
            Access critical data for research and advisory services.
          </span>
        </div>
      </div>

      <div className="col-sm-6 col-md-3 text-theme">
        <h2 className="text-theme feature-title">
          How it Works?
        </h2>
        <span className='d-block h30'></span>
        <div className='features'>
          <img height="25px" src="/assets/images/index/work1.png" alt="" />
          <span className='features-subtitle text-theme'>Data sources</span>
          <span className='feature-desc'>
            (public records, statutory filings, developer inputs).
          </span>
        </div>
        <div className='features'>
          <img height="25px" src="/assets/images/index/work2.png" alt="" />
          <span className='features-subtitle text-theme'>Project Milestones</span>
          <span className='feature-desc'>
            (Letter of Award to Completion).
          </span>
        </div>
        <div className='features'>
          <img height="25px" src="/assets/images/index/work3.png" alt="" />
          <span className='features-subtitle text-theme'>Interactive query.</span>
          <span className='feature-desc'></span>
        </div>
      </div>
    </div>
  </div>);
}

function SectionD() {
  return (
    <div className="body" style={{ "textAlign": "center", "paddingTop": "25px", "paddingBottom": "50px" }}>
      <div className="row sectionF-anim">
        <div className="col-lg-12">
          <div className='container-sm' style={{ "display": "flex", "flexDirection": "column", "justifyContent": "center", "gap": "15px", "paddingBottom": "50px" }}>
            <div className='text-theme' style={{ "fontSize": "25px", fontWeight: '600' }}>About Us</div>
            <p className='m-0 text-theme'>Roadsindia is a product conceived and developed by Akara Research & Technologies Pvt Ltd. --- a data-sciences driven, geo-visual (map) analytics company, incorporated to solve the myriad list of data & visualisation problems in enterprises & Governance. Co-founded by two professionals with two decades of experience each in the information & data sciences industry.</p>
          </div>
        </div>
    </div>
  </div>
  );
}

function Footer() {
  return (
    <div className="body" style={{ "background": "#061420", "padding": "0px 5vw" }}>
      <div style={{ "height": "1px", "backgroundColor": "#C7C7C7" }} className="button-filled-theme"></div>
      <div style={{ "height": "20px" }}></div>
      <div className="row" style={{ "paddingBottom": "20px" }}>
        <div className="col-12 col-md-4 text-theme emailid text-md-left">
          <a href="mailto: sales@akara.co.in" className='text-theme'>sales@akara.co.in</a>
        </div>
        {/* <div className="col-12 col-md-4 text-theme copyright text-center"> </div> */}
        <div className="col-12 col-md-8 text-theme appname">
          <a href="https://akara.co.in/" target='_blank' className='text-theme'>©{new Date().getFullYear()} Akara Research & Technologies Pvt Ltd | All rights reserved.</a>
        </div>
      </div>
    </div>
  );
}