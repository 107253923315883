import React, {Fragment, useState, Component} from 'react';
import cx from 'classnames';
import _ from 'lodash';

import {connect} from 'react-redux';

import {
    setSidebarToggleMobile
} from '../../reducers/ThemeOptions';
import { Row, Table, Col, CardBody, Card, CardTitle, CardHeader, Collapse, UncontrolledPopover, Container, InputGroup, InputGroupAddon, InputGroupText, FormText, Form, CustomInput, Label, FormGroup, Input, Badge, ListGroup, ListGroupItem, PopoverHeader, PopoverBody, UncontrolledTooltip, TabContent, TabPane, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledDropdown, Progress, DropdownToggle, DropdownMenu, Alert, DropdownItem } from 'reactstrap'

import {SidebarHeader, SidebarMenu, SidebarFooter} from '../../layout-components'
import { fnLogoutAuthentication, fnLoginAuthActions } from 'actions';
import { SliderDrawer, ChangePassword, UserProfile } from 'components';
import { cmnFnTogglePageDrawer } from 'actions/commoncross.actions';
import { USER_CHANGE_PWD_SAVE_REQUEST_CLEAR_MSG, SAVE_USER_PROFILE_DATA_REQUEST_RESET } from '../../constants';

class Sidebar extends Component {

    state = {
        isOpen: false,
        openFormFor: 1, //1 - Profile, 2 - Change Password
        stateOptions: [],
        districtOptions: {},
        userProfileForm: {},
        profileFormErr: {},
        userProfilePrevStateForm: {},
        resProfileMsg: ''
    };

    getSnapshotBeforeUpdate(prevProps) { 
        return {
            is_update_profile_init_data: (prevProps.profileInitDataLoading === true && this.props.profileInitDataLoading === false),
            is_update_profile_res_data: (prevProps.saveProfile.loading === true && this.props.saveProfile.loading === false),
            save_profile_res_msg:  this.props.saveProfile.msg || ''
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) { 
        if (snapshot.is_update_profile_init_data) {
            //this.afterDeletingLoadingResponse(snapshot, prevState, prevProps);

            let userData = this.props.profileInitData.user_data;
            let stateOptions = this.props.profileInitData.state_options;
            let districtOptions = this.props.profileInitData.district_options;
            let stateids = _.has(userData, 'm_state_id') ? [userData.m_state_id] : _.map(stateOptions, (obj) => obj.id);

            let stateObj = {
                id_login_user: this.props.logedInUser.id_login_user,
                user_name: userData.user_name,
                email_id: userData.email_id,
                gender: _.has(userData, 'gender') ? userData.gender : '',
                id_name_title: _.has(userData, 'id_name_title') ? userData.id_name_title : '',
                phone: _.has(userData, 'phone') ? userData.phone : '',
                mobile: _.has(userData, 'mobile') ? userData.mobile : '',
                address2: _.has(userData, 'address2') ? userData.address2 : '',
                address1: _.has(userData, 'address1') ? userData.address1 : '',
                city: _.has(userData, 'city') ? userData.city : '',
                pincode: _.has(userData, 'pincode') ? userData.pincode : '',
                m_state_id: _.has(userData, 'm_state_id') && +userData.m_state_id > 0 ? userData.m_state_id : _.noop(),
                m_district_id: _.has(userData, 'm_district_id') && +userData.m_district_id > 0 ? userData.m_district_id : _.noop(),
            };
            
            this.setState((prevState, curProps) => ({
                districtOptions: this.setDistrictDataBasedOnState(stateids, stateOptions, districtOptions),
                userProfileForm: stateObj,
                userProfilePrevStateForm: stateObj
            }));
        }

        if ( snapshot.is_update_profile_res_data ) { 
            //resProfileMsg
            this.setState((prevState, curProps) => ({
                ...prevState,
                resProfileMsg: snapshot.save_profile_res_msg
            }));
        }
    }

    setDistrictDataBasedOnState = (stateids, stateOptions, districtOptions) => { 
        
        let districtdata = {};

        stateids = _.filter(stateids);

        if ( _.size(stateids) > 0) {
            _.each(stateids, (state_id, indx) => {
                let state_data = _.find(stateOptions, ['id', `${state_id}`]);

                if (!(state_data.text in districtdata)) { 
                    districtdata[state_data.text] = [];
                }
                let _rs_obj = _.filter(districtOptions, ['m_state_id', `${state_id}`]);
                Array.prototype.push.apply(districtdata[state_data.text], _rs_obj);
            });
        }
        return districtdata;
    }

    onChangeStateGetDistricts = (state_id) => {
        this.setState({ districtOptions: +state_id > 0 ? this.setDistrictDataBasedOnState([state_id], this.props.profileInitData.state_options, this.props.profileInitData.district_options): {} });
    }

    handleChange = (e, name) => {
        let keyName = null;
        let val = null;
        if (!_.isEmpty(name)) { 
            keyName = name;
            val = e;
        } else {
            keyName = e.target.name;
            val = e.target.value;
        }

        this.setState((prevState, curProps) => {
            
            let _err = { ...prevState.profileFormErr };
            if (_.size(_err)) { 
                if (_.has(_err, keyName)) {
                    if (_.trim(val) != "" && !_.isNil(val)) {
                        if (!_.isNaN(+val) && +val > 0) {
                            _err = _.omit(_err, [keyName]);
                        } else if (!_.isEmpty(val)) { 
                            _err = _.omit(_err, [keyName]);
                        }
                    }              
                }
            }
            return {
                ...prevState,
                userProfileForm: { ...prevState.userProfileForm, [keyName]: val },
                profileFormErr: _err
            }
        });
        
        if (name == 'm_state_id') {   
            let state_id = _.isNil(val) ? -1 : val;
            this.onChangeStateGetDistricts(state_id);

            if (_.has(this.state.userProfileForm, 'm_district_id')) { 
                this.setState((prevState) => ({ ...prevState, userProfileForm: { ...prevState.userProfileForm, 'm_district_id': _.noop() } }));
            }            
        }
    }

    resetProfileForm = (e) => {
        e.preventDefault(); //resetUserProfileData
        this.props.resetUserProfileResMsg();
        this.setState((prevState) => ({ ...prevState, userProfileForm: prevState.userProfilePrevStateForm, profileFormErr: {} }));
        this.closeModalPopup();
    }

    submitUserProfileForm = (e) => {
        e.preventDefault();

        let formData = { ...this.state.userProfileForm };
        //profileFormErr

        let errorCheckFields = ['id_name_title', 'user_name', 'gender', 'address1', 'city', 'pincode', 'm_district_id', 'm_state_id'];

        let err = {};

        _.each(errorCheckFields, (field) => {            
            if (!_.has(formData, field) || _.isNil(_.get(formData, field)) || (_.has(formData, field) && _.isEmpty(_.trim(_.get(formData, field))))) { 
                err[field] = true;
            }
        });

        if (_.size(err)) { 
            this.setState((prevState) => ({...prevState, profileFormErr: err}));
        } else {
            this.props.saveUsersProfile(formData).then(res => {

                //Reset Update After save
                this.props.getUserProfileInitData({id_login_user: this.props.logedInUser.id_login_user});

            }).catch(err => {

            });
        }

    }

    toggleSidebarMobile = () => {
        let {sidebarToggleMobile, setSidebarToggleMobile} = this.props;
        setSidebarToggleMobile(!sidebarToggleMobile);
    }

    logout() {
        this.props.logout();
        localStorage.removeItem("my_project_count")
        localStorage.removeItem("my_query_count")
        localStorage.removeItem("user_type_id")
    }

    profileOpenForm = () => {
        this.props.getUserProfileInitData({id_login_user: this.props.logedInUser.id_login_user});
        this.setState({ openFormFor: 1, isOpen: true });
    }

    changePasswordOpenForm = () => {
        this.setState({ openFormFor: 2, isOpen: true });        
    }

    closeModalPopup = () => { 

        if (this.state.openFormFor === 1) {
            this.props.resetUserProfileResMsg();
        }else if (this.state.openFormFor === 2) {
            this.props.clearChangePwdMsg();
        }
        this.setState((prevState, curState) => ({ isOpen: false }));
    }

    render() {
        let {
            sidebarShadow,
            sidebarStyle,
            sidebarFooter,
            sidebarToggleMobile,
            logedInUser
        } = this.props;

        return (
            <Fragment>
                <div className={cx(
                    "app-sidebar", sidebarStyle,
                {'app-sidebar--shadow': sidebarShadow}
                    )}>
                    <SidebarHeader/>
                    <div className="app-sidebar--content">
                        <SidebarMenu road_project_count={this.props.count} road_qry_count={this.props.qry_count}/>
                    </div>
                    {sidebarFooter && 
                    <SidebarFooter 
                        loginUserData={logedInUser} 
                        logout={this.logout.bind(this)} 
                        changePasswordOpenForm={this.changePasswordOpenForm}
                        profileOpenForm={this.profileOpenForm} /> }
                </div>
                <div onClick={this.toggleSidebarMobile} className={cx("app-sidebar-overlay", {'is-active': sidebarToggleMobile})}/>

                <Modal zIndex={2000} backdrop={'static'} scrollable={true} size={this.state.openFormFor === 1 ?"lg": ""} contentClassName="modal-xl rounded-sm" centered isOpen={this.state.isOpen}>                    
                    <button type="button" className="close modalCloseBtn text-danger" onClick={this.closeModalPopup}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <ModalBody>                        
                        {this.state.openFormFor === 1 ?
                            <UserProfile {...this.props} {...this.state}
                                submitUserProfileForm={this.submitUserProfileForm} 
                                resetProfileForm={this.resetProfileForm} 
                                handleChange={this.handleChange} /> : <ChangePassword {...this.props} {...this.state} closeModalPopup={this.closeModalPopup} />}  
                    </ModalBody>                    
                </Modal>

            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    logedInUser: _.has(state.Authentication, 'user') ? state.Authentication.user : {},
    sidebarShadow: state.ThemeOptions.sidebarShadow,
    sidebarStyle: state.ThemeOptions.sidebarStyle,
    sidebarFooter: state.ThemeOptions.sidebarFooter,
    sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
    pageDrawerToggle: state.ThemeOptions.pageDrawerToggle || false,
    changePwd: state.Authentication.changePwd || {},
    profileInitDataLoading: state.Authentication.profileInitData.loading || false,
    profileInitData: state.Authentication.profileInitData.data || {},
    saveProfile: state.Authentication.saveProfile || {}
});

const mapDispatchToProps = dispatch => ({

    setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable)),
    logout: () => dispatch( fnLogoutAuthentication() ),
    setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable)),
    togglePageDrawer: (enable) => dispatch(cmnFnTogglePageDrawer(enable)),
    saveChangePassword: (data) => dispatch(fnLoginAuthActions.saveChangePassword(data)),
    clearChangePwdMsg: () => dispatch({ type: USER_CHANGE_PWD_SAVE_REQUEST_CLEAR_MSG }),
    resetUserProfileResMsg: () => dispatch({ type: SAVE_USER_PROFILE_DATA_REQUEST_RESET }),
    getUserProfileInitData: (data) => dispatch( fnLoginAuthActions.getUserProfileInitData(data) ),
    saveUsersProfile: (data) => dispatch(fnLoginAuthActions.saveUsersProfile(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);