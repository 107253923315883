import React, { useEffect, useMemo, useState } from 'react'
import L from "leaflet"
import './Icon'
import './icon.css'
import Axios from 'axios'
import { Request } from 'utils'
import { FeatureGroup } from 'leaflet'
import moment from 'moment'

//  const India_shape = '/assets/India.json'
var map
var road_layer_b={}
var road_layer_g=new L.FeatureGroup()
var pre_load_g=new L.FeatureGroup()
var pre_load_b={}
var fully_stretch_g=new L.FeatureGroup()
var fully_stretch_b={}
var district_b={}
var district_g=new L.FeatureGroup()
var toll_plaza_g=new L.FeatureGroup()
var toll_plaza_b={}
var l=false
export default function MapView({projectId,props={},state, height}) {
    const india_center=[21.146633, 79.08886]
    const [dislayer,setDisLayer]=useState('[]')
    const [layer,setLayer]=useState(false)
    const {tollMarker,stretch,m_district_id,district, showTollPlaze}=state

    const{projectData}=props

    const iniMap=()=>{
      map=L.map('map',{
        center:india_center,
        zoom: 5,
        preferCanvas: true,
        doubleClickZoom: false,
        zoomControl: true,
        scrollWheelZoom: false,
      })
      map.zoomControl.setPosition("topright");
      var osmUrl = "http://{s}.tile.osm.org/{z}/{x}/{y}.png";
      var osmAttr ='Map data &copy; <a href="https://osm.org/copyright" target="_blank">OpenStreetMap</a> contributors';
      L.tileLayer(osmUrl, { attribution: osmAttr }).addTo(map);
        fetch(`${process.env.PUBLIC_URL}/assets/India.json`).then(r => r.json()).then(async(e)=>{
          new L.GeoJSON(e).addTo(map).setStyle({
                   color: "#00000",
                   fill:false,
                   weight: 0.5,
                   opacity: 0.9,
                   fillOpacity:0
                 })

             })


     return Promise.resolve(true)
    }

  useEffect(()=>{
        iniMap().then(()=>{
          setDisLayer(projectData.district)
          layerGet()
        }).then(()=>{
          setLayer(stretch)
        })
    return () => map.remove();
  },[projectData])

  useEffect(()=>{
    district_g=new L.FeatureGroup()
    if([district_b].length>0){
     Object.keys((e)=>{
      if(map.hasLayer(district_b[e])){
        map.removeLayer(district_b[e])
      }
     })
    }
    setTimeout(()=>{
      if(projectData?.m_district_id?.length>0){
        JSON.parse(dislayer??'[]').map(async(e)=>{
         if(e.path!==null){
           await Axios.get(/^(http|https):\/\/[^ "]+$/.test(e.path)?e.path:atob(e.path)).then(async(res)=>{
             district_b[e.m_district_id]= new L.GeoJSON(await res.data).addTo(map).setStyle({color:'blue',fillColor:'white',fillOpacity:0.2,weight:0.5})
             if( district_b[e.m_district_id]){
              district_b[e.m_district_id].addTo(district_g)
             }
           }).catch((e)=>{

           })

           if( Object.keys(district_g.getBounds()).length>0){
            //  map.fitBounds(district_g.getBounds(),{maxZoom:14})
         }
         }
         })
       }
    },250)

},[dislayer])

  useEffect(()=>{
    tollLayer()
    // var data=[]
    //   data=tollMarker
    // console.log(data)
    // if(data??[].length>0){
    //   data.map((e,i)=>{
    //     if(e.toll_latitude!==''&&e.toll_longtitude!=='' && e.toll_latitude!==undefined&&e.toll_longtitude!==undefined){
    //       var marker=new L.marker([Number(e.toll_latitude),Number(e.toll_longtitude)],{icon:L.AwesomeMarkers.icon({
    //         icon:'fa-circle', iconColor:'#f4772e',className:'awesome-marker'
    //       }),autoPan: true}).addTo(pre_load_g).addTo(map)
    //       marker.bindPopup(`<div>
    //       <div>
    //       <div class='fw-bold'>${projectData.road_project_name}</div>
    //       <table  class="table table-bordered">
    //           <td>Toll Name</td>
    //           <td>${e.toll_name??'-'}</td>
    //         </tr>
    //         <tr>
    //         <td>Stretch</td>
    //         <td>${e.toll_length??'-'}</td>
    //       </tr>
    //       ${
    //         e.open_date?`<tr>
    //         <td>Functioning From</td>
    //         <td>${moment(parseInt(e.open_date)).format('MMM-YYYY')}</td>
    //       </tr>`:''
    //       }
    //       ${
    //         e.location_name?`<tr>
    //         <td>Location</td>
    //         <td>${e.location_name}</td>
    //       </tr>`:``
    //       }
    //       ${
    //         e.district_name?`<tr>
    //         <td>District </td>
    //         <td>${e.district_name}</td>
    //       </tr>`:``
    //       }

    //       ${
    //         e.facility_name?`<tr>
    //         <td>Facility</td>
    //         <td>${e.facility_name}</td>
    //       </tr>`:``
    //       }
    //       </table>

    //     </div>

    //       </div>`)
    //     }
    //   })
    // }
  },[tollMarker,state.tollMarker])

  const tollLayer=()=> {
    if(Object.keys(toll_plaza_b).length>0){
        Object.keys(toll_plaza_b).map(key => {
          if(map.hasLayer(toll_plaza_b[key])){
            map.removeLayer(toll_plaza_b[key])
          }
      })
    }

    var data=tollMarker
    console.log(data)
    if(data??[].length>0){
      data.map((e,i)=>{
        if(e.toll_latitude!==''&&e.toll_longtitude!=='' && e.toll_latitude!==undefined&&e.toll_longtitude!==undefined){
          toll_plaza_b[e.id_road_toll] = new L.marker([Number(e.toll_latitude),Number(e.toll_longtitude)],{icon:L.AwesomeMarkers.icon({
            icon:'fa-circle', iconColor:'#f4772e',className:'awesome-marker'
          }),autoPan: true}).addTo(pre_load_g).addTo(map)

          if(toll_plaza_b[e.id_road_toll]){
            toll_plaza_b[e.id_road_toll].addTo(toll_plaza_g)

            toll_plaza_b[e.id_road_toll].bindPopup(`<div>
            <div>
            <div class='fw-bold'>${projectData.road_project_name}</div>
            <table  class="table table-bordered">
                <td>Toll Name</td>
                <td>${e.toll_name??'-'}</td>
              </tr>
              <tr>
              <td>Stretch</td>
              <td>${e.toll_length??'-'}</td>
            </tr>
            ${
              e.open_date?`<tr>
              <td>Functioning From</td>
              <td>${moment(parseInt(e.open_date)).format('MMM-YYYY')}</td>
            </tr>`:''
            }
            ${
              e.location_name?`<tr>
              <td>Location</td>
              <td>${e.location_name}</td>
            </tr>`:``
            }
            ${
              e.district_name?`<tr>
              <td>District </td>
              <td>${e.district_name}</td>
            </tr>`:``
            }

            ${
              e.facility_name?`<tr>
              <td>Facility</td>
              <td>${e.facility_name}</td>
            </tr>`:``
            }
            </table>

          </div>

            </div>`)

           }
        }
      })
    }
  }

  const layerGet=()=>{
    console.log(stretch)
    if(Object.keys(fully_stretch_b).length>0){
      Object.keys(fully_stretch_b).map(key => {
        if(map.hasLayer(fully_stretch_b[key])){
          console.log(fully_stretch_b[key])
          map.removeLayer(fully_stretch_b[key])
        }
    })
    }
          if(stretch.length>0){

            Promise.all(stretch.map(async (e)=>{
              await  Axios.get(/^(http|https):\/\/[^ "]+$/.test(e.file_path)?e.file_path:atob(e.file_path)).then(async(json)=>{
                const data=await json.data
                  fully_stretch_b[e.m_s_status_id]=  new L.GeoJSON( data).addTo(map).setStyle({
                    color:e.m_project_status_color,
                    fillColor:'green',
                    fillOpacity:0.5,
                    weight:2,
                  })

                }).catch((e)=>{

                })
                if(fully_stretch_b[e.m_s_status_id]){
                  fully_stretch_b[e.m_s_status_id].addTo(fully_stretch_g)

                  fully_stretch_b[e.m_s_status_id].bindPopup(`<div>
                  <div>
                    <div  class='fw-bold'>${projectData?.road_project_name}</div>
                    <table  class="table table-bordered">
                        <td>Length (kms)</td>
                        <td>${e.stretch_length??'-'}</td>
                      </tr>
                      <tr>
                      <td>Status</td>
                      <td>${e.s_status_name??'-'}</td>
                    </tr>
                    <td>Remark</td>
                    <td>${e.remarks??'-'}</td>
                  </tr >

                    </tr>
                    </table>

                  </div>
           </div>`)
                }




            })
            ).then(()=>{
              if(Object.keys(fully_stretch_g.getBounds()).length>0){
                map.fitBounds(fully_stretch_g.getBounds(),{maxZoom:8})
            }
            })


          }


  }
  useEffect(()=>{
    setTimeout(()=>{
      layerGet()
    },300)
  },[layer])

  useEffect(()=>{
    if(showTollPlaze) {
      tollLayer()
    } else {
      if(Object.keys(toll_plaza_b).length>0){
        Object.keys(toll_plaza_b).map(key => {
          if(map.hasLayer(toll_plaza_b[key])){
            // console.log(toll_plaza_b[key])
            map.removeLayer(toll_plaza_b[key])
          }
        })
     }
   }
  },[showTollPlaze])

    return(
        <>
    <div>
      <div id="map" style={{width:"100%",height: '400px'}}></div>
    </div>
        </>
    )


}
