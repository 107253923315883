import React, { lazy, Suspense, Fragment, useEffect, useState } from 'react';
import { Switch, Route, Redirect, Navigate, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from "framer-motion";
import { ClimbingBoxLoader } from 'react-spinners';
import { LeftSidebar, MinimalLayout } from './layout-blueprints';
import { useSelector, shallowEqual } from 'react-redux';
import _ from 'lodash';
import packageJson from '../package.json';
// Example Pages
const LandingPage = lazy(() => import('./pages/LandingPage'));
const PageNotFound = lazy(() => import('./pages/PageNotFound'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const Users = lazy(() => import('./pages/Users'));
const LocalAdminUsers = lazy(() => import('./pages/LocalAdmin/'));
const Company = lazy(() => import('./pages/Company/'));
const Companyupdatelog = lazy(() => import('./pages/Company/UpadteLog'));
const AddCompanyFinance = lazy(() => import('./pages/Company/AddFinance'));
const Companygroup = lazy(() => import('./pages/Company/Companygroup'));
const AddUpdateCompany = lazy(() => import('./pages/Company/AddCompany'));
const RoadProjects = lazy(() => import('./pages/Road/Projects'));
const AddRoadProjects = lazy(() => import('./pages/Road/AddProjects'));
const RoadProjectsViewDetails = lazy(() => import('./pages/Road/ProjectsViewDetails'));
const RoadQueryPage = lazy(() => import('./pages/Road/Query'));
const Roadupdatelog = lazy(() => import('./pages/Road/Updatelog'));
const Myprojectlist = lazy(() => import('./pages/Road/MyProjectList'))
const UnpublishProjects = lazy(() => import('./pages/Road/Unpublished'))
const Myquerylist = lazy(() => import('./pages/Road/MyQuerList'))
const DataSource = lazy(() => import('./pages/Knowledge/DataSource'))
const ProjectCycle = lazy(() => import('./pages/Knowledge/ProjectCycle'))
const ProjectFinance = lazy(() => import('./pages/Knowledge/ProjectFinance'))
const Glossary = lazy(() => import('./pages/Knowledge/Glossary'))
const Test = lazy(() => import('./pages/Test'));

const Routes = () => {

    const location = useLocation();
    const store = useSelector(state => ({ ...state }), shallowEqual);
    const { Authentication: auth } = store;
    const Action = (_.has(auth, 'isLoggedIn') && auth.isLoggedIn) ? _.get(auth, 'user.action') : {};
    var road_project_count = localStorage.getItem("my_project_count")
    var road_qry_count = localStorage.getItem("my_query_count")
    const [refresh, setRefresh] = useState(false)

    if (window.location.pathname.includes("/clist") === false && !window.location.pathname.includes("/cadd") && !window.location.pathname.includes("/rdprojectsview")) {
        localStorage.removeItem('gf')
    }

    if (localStorage.getItem('version') !== packageJson.version) {
        localStorage.clear()
        window.location.reload()
        localStorage.setItem("version", packageJson.version.toString())
    }

    if (localStorage.getItem('user_type_id') && !window.location.pathname === '/') {
        localStorage.clear()
        window.location.reload()
        localStorage.setItem("version", packageJson.version.toString())
    }

    let PathComponentObj = [];
    let pathUrls = [];
    if (_.size(Action)) {
        const Menu = Action.MENU;
        PathComponentObj = [
            { url: '/dashboard', access: Menu.DASHBOARD, component: Dashboard },
            { url: '/manageusers', access: Menu.MANAGE_USERS, component: Users },
            { url: '/lausers', access: Menu.MANAGE_LOCAL_ADMIN_USERS, component: LocalAdminUsers },
            { url: '/clist', access: Menu.COMPANY, component: Company },
            { url: '/cadd', access: Action.COMPANY.ALLOW_ADD, component: AddUpdateCompany },
            { url: '/cgroup', access: Menu.COMPANY_GROUP, component: Companygroup },
            { url: '/cfinance', access: Action.COMPANY.ALLOW_FINANCE_ADD, component: AddCompanyFinance },
            { url: '/cupdatelog', access: Menu.COMPANY_UPDATE_LOG, component: Companyupdatelog },
            { url: '/rdprojects', access: Menu.ROAD_PROJECT_LIST, component: RoadProjects },
            { url: '/rdprojectsadd', access: Action.ROAD_PROJECT_LIST.ALLOW_ADD || Action.ROAD_PROJECT_UNPUBLISH.ALLOW_ADD, component: AddRoadProjects },
            { url: '/rdprojectsview', access: Action.ROAD_PROJECT_LIST.ALLOW_VIEW_MORE || Action.ROAD_PROJECT_UNPUBLISH.ALLOW_VIEW_MORE, component: RoadProjectsViewDetails },
            { url: '/rdqry', access: Menu.ROAD_QUERY, component: RoadQueryPage },
            { url: '/rdupdatelog', access: Menu.ROAD_UPDATE_LOG, component: Roadupdatelog },
            { url: '/myprojectlist', access: Menu.ROAD_MY_PROJECT, component: Myprojectlist },
            { url: '/myqrylist', access: Menu.ROAD_MY_QUERY, component: Myquerylist },
            { url: '/unpublishproject', access: Menu.ROAD_PROJECT_UNPUBLISH, component: UnpublishProjects },
            { url: '/datasource', access: true, component: DataSource },
            { url: '/cycleinfo', access: true, component: ProjectCycle },
            { url: '/financeinfo', access: true, component: ProjectFinance },
            { url: '/glossary', access: true, component: Glossary },
            { url: '/test', access: true, component: Test },
        ];
        PathComponentObj = _.filter(PathComponentObj, ['access', true]);
        pathUrls = _.map(PathComponentObj, 'url');
    }

    const pageVariants = {
        initial: {
            opacity: 0,
        },
        in: {
            opacity: 1,
        },
        out: {
            opacity: 0,
        }
    };

    const pageTransition = {
        type: "tween",
        ease: "anticipate",
        duration: 2.0
    };

    const SuspenseLoading = () => {
        return (
            <Fragment>
                <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
                    <div className="d-flex align-items-center flex-column px-4">
                        <ClimbingBoxLoader color={"#3c44b1"} loading={true} />
                    </div>
                    <div className="text-muted font-size-xl text-center pt-3">
                        Please wait while we load the page
                    </div>
                </div>
            </Fragment>
        );
    }

    let loggedInContent = (
        // <Switch>
        //     <Redirect exact from="/" to="/dashboard" />
        //     <Route exact path={pathUrls}>
        //         <LeftSidebar count={road_project_count} qry_count={road_qry_count} setRefresh={setRefresh}>
        //             <Switch location={location} key={location.pathname} >
        //                 {_.map(PathComponentObj, (obj, indx) => (<Route path={obj.url}  component={obj.component} key={indx} /> ))}
        //             </Switch>
        //         </LeftSidebar >
        //     </Route>
        //     <Route path="*">
        //         <MinimalLayout>
        //             <Switch location={location} key={location.pathname}>
        //                 <Route path="*"  component={ PageNotFound } />
        //             </Switch>
        //         </MinimalLayout>
        //     </Route>
        // </Switch>

        <Switch location={location} key={location.pathname}>
            <Redirect exact from="/" to="/dashboard" />
            <Route path={pathUrls}>
                <LeftSidebar count={road_project_count} qry_count={road_qry_count} setRefresh={setRefresh}>
                    {/* <Switch>
                        {_.map(PathComponentObj, (obj, indx) => (
                            <Route path={obj.url} component={obj.component} key={indx} />
                        ))}
                    </Switch> */}
                    <Switch>
                        {_.map(PathComponentObj, (obj, indx) => (
                            <Route
                            path={obj.url}
                            key={obj.url}
                            render={(props) => (
                                <obj.component {...props} uniqueKey={obj.url + indx} />
                            )}
                            />
                        ))}
                    </Switch>
                </LeftSidebar>
            </Route>
            <Route path="*">
                <MinimalLayout>
                    <PageNotFound />
                </MinimalLayout>
            </Route>
        </Switch>

    );


    return (
        <AnimatePresence>
            <Suspense fallback={<SuspenseLoading />}>
                <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}
                >
                    {!auth.isLoggedIn ? (<Switch><Route exact path="/" component={LandingPage} /><Route path="*"> <Redirect to="/" /> </Route> </Switch>) : (loggedInContent)}
                </motion.div>
            </Suspense>
        </AnimatePresence>
    );
};
export default Routes;