import React, { Fragment, Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Badge, ListGroup, ListGroupItem, UncontrolledTooltip, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'

export default class SidebarFooter extends Component {

    render() {
        const { loginUserData, changePasswordOpenForm, profileOpenForm } = this.props;

        return (
            <Fragment>
                <div className="app-sidebar--footer">
                    <div className="app-sidebar--userbox">
                        <div className="card-tr-actions">
                            <UncontrolledDropdown className="card-tr-actions">
                                <DropdownToggle color="" className="p-0 border-0 text-white-50 no-caret">
                                    <FontAwesomeIcon icon={['fas', 'ellipsis-h']} className="font-size-lg" />
                                </DropdownToggle>
                                <DropdownMenu right className=" text-nowrap overflow-hidden">
                                    <ListGroup flush className="text-left bg-transparent">
                                        <ListGroupItem className="">
                                            <DropdownItem onClick={e => { profileOpenForm(); } }>Profile</DropdownItem>
                                        </ListGroupItem>
                                        <ListGroupItem className="">
                                            <DropdownItem onClick={e => { changePasswordOpenForm(); } }>Change Password</DropdownItem> 
                                        </ListGroupItem>
                                    </ListGroup>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                        <div className="avatar-icon-wrapper avatar-icon-md">
                            <Badge color="success" className="badge-circle">online</Badge>
                            <div className="avatar-icon rounded-circle"><img alt="..." src="/assets/images/male-user.png" /></div>
                        </div>
                        <div className="my-3 userbox-details">
                            {loginUserData.user_name}
                            <small className="d-block">{loginUserData.user_type ? loginUserData.user_type : 'Unknown Type'}</small>
                        </div>
                        <Button size="sm" color="primary" onClick={e => { e.preventDefault(); this.props.logout(); }}>Logout</Button>
                    </div>
                </div>
            </Fragment>
        );
    }
}
