import React, { Component, Fragment, useState, useMemo, useEffect } from 'react';
import _ from 'lodash';
import cx from 'classnames';
import Select, { Option } from 'rc-select';
import { Button, UncontrolledTooltip, Row, Col, Card, CardBody, Label, InputGroup, Input, InputGroupAddon, CardHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Loader, Pagination } from 'components';
import { useTable, usePagination, useGlobalFilter } from 'react-table';
import { useHistory } from "react-router";
import Skeleton from 'react-loading-skeleton';

function AvailableProject(props) {

    const navigate = useHistory();
    const [projectdata, setProjectdata] = useState([props.projectData])
    
    const viewlogdata = (obj) => {
        props.close();
        let url = window.location.href;

        if (url.includes('rdprojectsview')) {
            window.open('/rdprojectsview', { rname: 'update', value: obj })
        }
        else {
            if (props.rname === "road") {
                navigate.push('/rdprojectsview', { rname: 'update', value: obj });
            } else {
                navigate.push('/rdprojectsview', { cname: 'update', value: obj });
            }
        }

    }

    const columnObj = [
        { Header: '#', accessor: '', style: { width: '3%' }, className: 'text-center', Cell: ({ row }) => <div > {row.index + 1}</div> },
        { Header: 'Stretch Name', accessor: 'road_stretch_name', style: { width: '20%' }, className: 'text-left' },
        { Header: 'Mode', accessor: 'project_mode', style: { width: '5%' }, className: 'text-left' },
        { Header: 'Status', accessor: 'project_status', style: { width: '17%' }, className: 'text-left' },
        {
            Header: 'Total Project Cost/km (Rs.Cr)', accessor: 'tpc_per_km', style: { width: '10%', textAlign: "right" }, className: 'text-right', Cell: ({ row }) => {
                return (
                    <div>{row.values.tpc_per_km}</div>
                )
            }
        },
        {
            Header: 'Total Project Cost  (Rs.Cr)', accessor: 'tpc', style: { width: '10%', textAlign: "right" }, className: 'text-right', Cell: ({ row }) => {
                return (
                    <div>{row.values.tpc}</div>
                )
            }
        },
        {
            Header: 'Time Overrun', accessor: 'time_overrun', style: { width: '10%', textAlign: "center" }, className: 'text-center', Cell: ({ row }) => {
                return (
                    !_.eq(row.values.time_overrun, '') ? _.eq(row.values.time_overrun, 'NA') ? 'NA' : (
                        <>
                        <Fragment>
                            {row.values.time_overrun} <small className='font-weight-light'> Months</small>
                        </Fragment>
                        </>
                    ) : '-'
                )
            }
        },
    ];
    columnObj.push({
        Header: '', accessor: 'id_road_project', style: { width: '4%' }, id: 'action_columns', Cell: ({ row }) => {
            let _row = row.original;

            let data = 1

            return (
                <div className=" justify-content-between align-items-center flex-nowrap action-col-cell">

                    <Fragment>
                        <UncontrolledTooltip trigger="hover" popperClassName={'tooltip-info'} placement="top" container="body" target={'view_more_btn_company' + _row.id_road_project
                        }>  {'View Details'}
                        </UncontrolledTooltip>

                        <Button onClick={() => viewlogdata(_row.id_road_project, data)} outline size="sm" className="btn-sm-icon btn-outline-slack border-1" id={'view_more_btn_company' + _row.id_road_project}>
                            <span className="btn-wrapper--icon">
                                <FontAwesomeIcon icon={['fas', 'eye']} className="opacity-8 font-size-xs" />
                            </span>
                        </Button>
                    </Fragment>

                </div>
            );
        },
        sortable: false,
        className: 'text-center',
    });

    const columns = useMemo(() => columnObj, []);
    let res_data = props.projectData ? props.projectData : [];
    let allData = props.projectAllData ? props.projectAllData : [];
    let ProjectMode = props.projectAllData.project_mode ? props.projectAllData.project_mode : []
    let EPCMode = Object.values(ProjectMode)
    let data = React.useMemo(() => res_data, [res_data]);

    const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, nexPage, previousPage, gotoPage, setPageSize, state, setGlobalFilter } = useTable({ columns, data }, useGlobalFilter, usePagination);

    const { pageIndex, pageSize, globalFilter } = state

    const onPageChange = (current, pageSize) => {
        gotoPage(current - 1)

    }

    const changePage = (PageChange, size) => {
        setPageSize(size)
    }
    useEffect(() => {
        changePage(1, res_data.length ? Number(res_data.length) : 100)
    }, [])

    let company_address = [];
    if (!_.isEmpty(props.company.address_1)) {
        company_address.push(props.company.address_1);
    }
    if (!_.isEmpty(props.company.address_2)) {
        company_address.push(props.company.address_2);
    }
    if (!_.isEmpty(props.company.address_3)) {
        company_address.push(props.company.address_3);
    }
    if (!_.isEmpty(props.company.city)) {
        company_address.push(props.company.city);
    }
    if (!_.isEmpty(props.company.district_name)) {
        company_address.push(props.company.district_name);
    }
    if (!_.isEmpty(props.company.state_name)) {
        company_address.push(props.company.state_name);
    }
    if (!_.isEmpty(props.company.pincode)) {
        company_address.push(props.company.pincode);
    }

    let websiteUrl = null;
    if (!_.isEmpty(props.company.website_url)) {
        websiteUrl = (<a href={'http://' + props.company.website_url} target="_blank">{props.company.website_url}</a>);
    }

    return (
        <Fragment>
            <div className="px-4 py-2 page-drawer-header-container d-flex justify-content-between align-items-center">
                <div className="text-left">
                    <h6 className="mb-0 text-black" style={{ marginTop: '5px' }}>Available Projects for the {props.ctype === "group" ? 'group' : 'developer'} <span className='fw-bold' style={{color:"#0c1159"}}>{props.ctype === "group" ? props.company?.company_group_name : props.company?.company_name}</span></h6>
                </div>
                <div className='d-flex justify-content-end mb-2'>
                    {/* <span className="filter-label-text" >Filter By Mode</span>
                    <Select allowClear dropdownClassName="rc-dropdown-styles" value={state.page.filterMode} placeholder={_.size(props.initData.filterProjectModeOptions) ? "Filter Project Mode" : "No data available"} className="mr-3" optionLabelProp="children" onChange={e => this.filterDataChangeForProjectMode(e)} optionFilterProp="text" multiple={true} style={{ width: '250px' }}>
                        {_.map(props.initData.filterProjectModeOptions, (obj, indx) => (<Option value={obj.id} text={obj.name} key={obj.id}>{obj.name}</Option>))}
                    </Select> */}
                    {data.length > 10 &&
                    <InputGroup style={{ maxWidth: '250px' }} className="filter-input-group">
                        <InputGroupAddon addonType="prepend"><Button size="sm" className="rounded-0 border-0 transform-none box-shadow-none"><FontAwesomeIcon icon={['fas', 'search']} /></Button></InputGroupAddon>
                        <Input placeholder="Search" className="form-control form-control-sm rounded-0" value={globalFilter || ""} onChange={(e) => setGlobalFilter(e.target.value)} style={{ width: '275px' }} />
                    </InputGroup>
                    }
                    <Fragment>
                        <Button size="sm" color="link" id="export_data_btn" onClick={()=> props.downloadExcel(globalFilter)} className="ml-2 add-update-btn-page-title">
                            <span className="btn-wrapper--icon">
                                <FontAwesomeIcon icon={['fas', 'file-excel']} className="" /> Export
                            </span>
                        </Button>
                        <UncontrolledTooltip popperClassName={'tooltip-info'} placement="top" target="export_data_btn">
                            Export Data To Excel
                        </UncontrolledTooltip>
                    </Fragment>
                </div>
            </div>
            <div className="divider" />
            <div className='p-3'>
            <Card className="card-list-box project-list-card card mb-0">
                <CardBody className="pb-0 pt-2">
                    <Row>
                    <Col sm={3} md={3} className="mb-3">
                        <small className="font-weight-light">Company Name</small>
                        <div className="text-dark">{props.company.company_name ? props.company.company_name : '-'}</div>
                    </Col>
                    <Col sm={3} md={3} className="mb-3">
                        <small className="font-weight-light">Parent Company</small>
                        <div className="text-dark">{props.company?.parent_company_name ? props.company.parent_company_name : '-'}</div>
                    </Col>
                    <Col sm={3} md={3} className="mb-3">
                        <small className="font-weight-light">Company Type</small>
                        <div className="text-dark">{props.company.company_type ? props.company.company_type : ''}</div>
                    </Col>
                    <Col sm={3} md={3} className="mb-3">
                        <small className="font-weight-light">CIN #</small>
                        <div className="text-dark">{props.company.cin_no ? props.company.cin_no : '-'}</div>
                    </Col>
                    <Col sm={3} md={3} className="mb-3">
                        <small className="font-weight-light">Address</small>
                        <div className="text-dark">{company_address ? company_address.join(', ') : '-'}</div>
                    </Col>
                    <Col sm={3} md={3} className="mb-3">
                        <small className="font-weight-light">Email Id</small>
                        <div className="text-dark">{props.company.email_id ? props.company.email_id : '-'}</div>
                    </Col>
                    <Col sm={3} md={3} className="mb-3">
                        <small className="font-weight-light">Telephone #</small>
                        <div className="text-dark">{props.company.phone_no ? props.company.phone_no : '-'}</div>
                    </Col>
                    <Col sm={3} md={3} className="mb-3">
                        <small className="font-weight-light">Website</small>
                        <div className="text-dark">{props.company.websiteUrl ? props.company.websiteUrl : '-'}</div>
                    </Col>
                    </Row>
                </CardBody>
            </Card>
            </div>
            <div className="px-3 mode dashboard-sm mb-3">
                <div className="card-list-box px-3 dboard-status-card-box h-100 mx-2 py-3 card">
                    <div className="d-flex flex-column h-100 justify-content-between">
                        <div className="status-right-content mt-3">
                            <div className="d-flex" style={{justifyContent: 'space-around'}}>
                                <div className="d-flex" style={{justifyContent: 'space-between', width: '85%'}}>
                                    <div className="d-flex flex-column" style={{justifyContent: 'space-around',  width: '85%'}}>
                                        <span className="text-title grptitle">PPP</span>
                                        <div className="d-flex" style={{justifyContent: 'space-around'}}>
                                            <div className="mode-list">
                                                <div className="p-2">
                                                    <span className="text-title">BOOT</span>
                                                    <div className="cost-amt-fsize count2">{allData?.ppp_mode?.BOOT.total_project ? allData?.ppp_mode?.BOOT.total_project : 0}</div>
                                                    <div className="cost-amt-fsize mt-2"><FontAwesomeIcon icon={['fas', 'rupee-sign']} />{allData?.ppp_mode?.BOOT.project_cost_format ? allData?.ppp_mode?.BOOT.project_cost_format : 0}<span className="cost-cr-fsize">Cr</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mode-divider"></div>
                                            <div className="mode-list">
                                                <div className="p-2">
                                                    <span className="text-title">BOT Annuity</span>
                                                    <div className="cost-amt-fsize count2">{allData?.ppp_mode?.BOT_Annuity.total_project ? allData?.ppp_mode?.BOT_Annuity.total_project : 0}</div>
                                                    <div className="cost-amt-fsize mt-2"><FontAwesomeIcon icon={['fas', 'rupee-sign']} />{allData?.ppp_mode?.BOT_Annuity.project_cost_format ? allData?.ppp_mode?.BOT_Annuity.project_cost_format : 0}<span className="cost-cr-fsize">Cr</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mode-divider"></div>
                                            <div className="mode-list">
                                                <div className="p-2">
                                                    <span className="text-title">BOT Toll</span>
                                                    <div className="cost-amt-fsize count2">{allData?.ppp_mode?.BOT_Toll.total_project ? allData?.ppp_mode?.BOT_Toll.total_project : 0}</div>
                                                    <div className="cost-amt-fsize mt-2"><FontAwesomeIcon icon={['fas', 'rupee-sign']} />{allData?.ppp_mode?.BOT_Toll.project_cost_format ? allData?.ppp_mode?.BOT_Toll.project_cost_format : 0}<span className="cost-cr-fsize">Cr</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mode-divider"></div>
                                            <div className="mode-list">
                                                <div className="p-2">
                                                    <span className="text-title">DBFOT</span>
                                                    <div className="cost-amt-fsize count2">{allData?.ppp_mode?.DBFOT.total_project ? allData?.ppp_mode?.DBFOT.total_project : 0}</div>
                                                    <div className="cost-amt-fsize mt-2"><FontAwesomeIcon icon={['fas', 'rupee-sign']} />{allData?.ppp_mode?.DBFOT.project_cost_format ? allData?.ppp_mode?.DBFOT.project_cost_format : 0}<span className="cost-cr-fsize">Cr</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mode-divider"></div>
                                            <div className="mode-list">
                                                <div className="p-2">
                                                    <span className="text-title">HAM</span>
                                                    <div className="cost-amt-fsize count2">{allData?.ppp_mode?.HAM.total_project ? allData?.ppp_mode?.HAM.total_project : 0}</div>
                                                    <div className="cost-amt-fsize mt-2"><FontAwesomeIcon icon={['fas', 'rupee-sign']} />{allData?.ppp_mode?.HAM.project_cost_format ? allData?.ppp_mode?.HAM.project_cost_format : 0}<span className="cost-cr-fsize">Cr</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mode-divider"></div>
                                            <div className="mode-list">
                                                <div className="p-2">
                                                    <span className="text-title">Total</span>
                                                    <div className="cost-amt-fsize count2">{EPCMode[0]?.total_project ? EPCMode[0].total_project : 0}</div>
                                                    <div className="cost-amt-fsize mt-2"><FontAwesomeIcon icon={['fas', 'rupee-sign']} />{EPCMode[0]?.project_cost_format ? EPCMode[0].project_cost_format : 0}<span className="cost-cr-fsize">Cr</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mode-divider"></div>
                                    <div className="mode-list">
                                        <div className="p-2">
                                            <span className="text-title">EPC</span>
                                            <div className="cost-amt-fsize count2">{EPCMode[1]?.total_project ? EPCMode[1].total_project : 0}</div>
                                            <div className="cost-amt-fsize mt-2"><FontAwesomeIcon icon={['fas', 'rupee-sign']} />{EPCMode[1]?.project_cost_format ? EPCMode[1].project_cost_format : 0}<span className="cost-cr-fsize">Cr</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mode-divider"></div>
                                <div className="mode-list">
                                    <div className="p-2">
                                        <span className="text-title">Total Project</span>
                                        <div className="cost-amt-fsize count2">{allData?.project?.total_project ? allData?.project?.total_project : 0}</div>
                                        <div className="cost-amt-fsize mt-2"><FontAwesomeIcon icon={['fas', 'rupee-sign']} />{allData?.project?.project_cost ? allData?.project?.project_cost : 0}<span className="cost-cr-fsize">Cr</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='px-3 pt-0 pb-3 company-custom-table'>
                <Card className="card-list-box mb-0 header p-0" style={{backgroundColor: 'transparent', boxShadow: 'none'}}>
                <CardBody className="py-2">
                    <Row className="d-flex align-items-center">
                        <Col className="sno">
                        <div className=" label-card">
                            <small className="font-weight-light">#</small>
                        </div>
                        </Col>
                        <Col sm={3}>
                            <div className=" label-card">
                                <small className="font-weight-light">Stretch Name</small>
                            </div>
                        </Col>
                        <Col className="mode">
                        <div className=" label-card text-center">
                            <small className="font-weight-light">Mode</small>
                        </div>
                        </Col>
                        <Col>
                        <div className=" label-card text-right">
                            <small className="font-weight-light">Total Project Cost/km (Rs.Cr)</small>
                        </div>
                        </Col>
                        <Col>
                        <div className=" label-card text-right">
                            <small className="font-weight-light">Total Project Cost (Rs.Cr)</small>
                        </div>
                        </Col>
                        <Col>
                        <div className=" label-card text-center">
                            <small className="font-weight-light">Lane (Km)</small>
                        </div>
                        </Col>
                        <Col>
                        <div className=" label-card text-center">
                            <small className="font-weight-light">Status</small>
                        </div>
                        </Col>
                        <Col sm={1}>
                        <div className=" label-card text-center">
                            <small className="font-weight-light">Time Overrun</small>
                        </div>
                        </Col>
                        <Col sm={1} className="fin-col text-center">
                        <small className="font-weight-light">View</small>
                        </Col>
                    </Row>
                    </CardBody>
                </Card>
                <div style={{ height: data.length > 10 ? "550px" : "auto", overflowY: "auto" }}>
                {_.size(page) === 0 ? (
                    <div className='p-5 d-flex justify-content-center align-items-center'>
                        No Data Found
                    </div>
                ):
                page.map((row, r) => {
                    let _row = row.original
                    let data = 1
                    prepareRow(row)
                    return (
                        <Card key={`3`+r} {...row.getRowProps()} className="card-list-box project-list-card" style={{backgroundColor: '#F5F5F5', boxShadow: 'none'}}>
                            <CardBody className="py-2">
                                <Row className="d-flex align-items-center">
                                    <Col className="sno">
                                        <div className=" label-card">
                                            <div>
                                                <div className="text-dark">{row.index + 1}</div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={3}>
                                    <div className=" label-card">
                                            <div>
                                                <div className="text-dark"> {_row.road_stretch_name ? _row.road_stretch_name : ''}</div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className="mode">
                                    <div className=" label-card text-center">
                                        <div>
                                            <div className="text-dark">{_row.project_mode ? _row.project_mode : '-'}</div>
                                        </div>
                                    </div>
                                    </Col>
                                    <Col>
                                    <div className=" label-card text-right">
                                        <div>
                                            <div className="text-dark">{row.values.tpc_per_km}</div>
                                        </div>
                                    </div>
                                    </Col>
                                    <Col>
                                    <div className=" label-card text-right">
                                        <div>
                                            <div className="text-dark">{row.values.tpc}</div>
                                        </div>
                                    </div>
                                    </Col>
                                    <Col>
                                    <div className=" label-card text-center">
                                        <div>
                                            <div className="text-dark">{_row.lane_km ? _row.lane_km: '-' }</div>
                                        </div>
                                    </div>
                                    </Col>
                                    <Col>
                                    <div className=" label-card text-center">
                                        <div>
                                            <div className="text-dark">{_row.project_status ? _row.project_status: '-' }</div>
                                        </div>
                                    </div>
                                    </Col>
                                    
                                    <Col sm={1}>
                                    <div className=" label-card text-center">
                                        <div>
                                            <div className="">
                                                {
                                                    !_.eq(row.values.time_overrun, '') ? _.eq(row.values.time_overrun, 'NA') ? 'NA' : (
                                                        <>
                                                        <Fragment>
                                                            {row.values.time_overrun}  Months
                                                        </Fragment>
                                                        </>
                                                    ) : '-'
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    </Col>
                                    <Col sm={1} className="fin-col">
                                    <div className=" justify-content-between align-items-center flex-nowrap action-col-cell">
                                        <Fragment>
                                            <UncontrolledTooltip trigger="hover" popperClassName={'tooltip-info'} placement="top" container="body" target={'view_more_btn_company' + _row.id_road_project}>{'View Details'}
                                            </UncontrolledTooltip>
                                            <Button type="button" color="link" title="View Details" className=" btn btn-sm btn-link" onClick={() => viewlogdata(_row.id_road_project, data)} id={'view_more_btn_company' + _row.id_road_project}>
                                                <span className='btn-wrapper--icon icon'><FontAwesomeIcon icon={['fas', 'eye']} size="lg" className='m-0' /></span></Button>
                                            {/* <a href="#" onClick={(e) => { e.preventDefault(); viewlogdata(_row.id_road_project, data) }} className="btn btn-sm btn-primary-light border-1" id={'view_more_btn_company' + _row.id_road_project}>
                                                <FontAwesomeIcon icon={['fas', 'eye']} size="lg" />
                                            </a> */}
                                        </Fragment>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    )
                })}
                </div>
                {/*<Card className="border-0">
                    <CardBody className="py-2">
                            
                        <div style={{ height: data.length > 15 ? "600px" : "auto", overflowY: "auto" }}>
                            
                            
                            <table {...getTableProps({ className: "table table-bordered table-hover" })}>
                                <thead>
                                    {headerGroups.map((headerGroup,h) => (
                                        <tr key={`1`+h} {...headerGroup.getHeaderGroupProps(
                                        )}>
                                            {headerGroup.headers.map((column, c) => (
                                                <th key={`2`+c} {...column.getHeaderProps({ className: column.className ? column.className : '', style: column.style ? column.style : {} })}>{column.render('Header')}</th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {_.size(page) === 0 ? (
                                        <tr>
                                            <td key={'none'} colSpan={_.size(columns)} className="text-center">No Data Found</td>
                                        </tr>
                                    ) : page.map((row, r) => {

                                        prepareRow(row)
                                        return (
                                            <>
                                                <tr key={`3`+r} {...row.getRowProps()}>
                                                    {row.cells.map((cell, ce) => {
                                                        return <td key={`4`+ce} {...cell.getCellProps({ className: cell.column.className ? cell.column.className : '' })}>{cell.render('Cell')}</td>
                                                    })}
                                                </tr>
                                            </>
                                        )
                                    })}
                                </tbody>
                            </table> 
                        </div>

                    </CardBody>
                </Card>*/}

                {data.length > (res_data.length ? Number(res_data.length) : 100) &&
                    <Pagination
                        selectComponentclassName={Select}
                        pageSizeOptions={['10', '25', '50', '75', '100']}
                        showTotal={(total, [from, to]) => { let _from = +from < 0 ? 1 : from; let _to = to > -1 ? to : total; return (<>{_from} - {_to} Of {total}</>) }}
                        defaultPageSize={10}
                        pageSize={pageSize}

                        defaultCurrent={1}
                        // current={page1.current}
                        total={data.length}
                        onChange={onPageChange}
                        onShowSizeChange={changePage}
                        style={{ borderRadius: '4px', fontSize: '12px' }}
                        wrapperClassName={cx('p-2 pagination-container')}
                    />}

            </div>
        </Fragment>
    );

}
export default AvailableProject;