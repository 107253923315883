import React, {Fragment, useState, Component, useRef} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Row, Table, Col, CardBody, Card, CardTitle, CardHeader, Collapse, UncontrolledPopover, Container, InputGroup, InputGroupAddon, InputGroupText, FormText, Form, CustomInput, Label, FormGroup, Input, Badge, ListGroup, ListGroupItem, PopoverHeader, PopoverBody, UncontrolledTooltip, TabContent, TabPane, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledDropdown, Progress, DropdownToggle, DropdownMenu, Alert, DropdownItem } from 'reactstrap'

import {connect, useSelector, shallowEqual} from 'react-redux';
import RouterLink from './react-metismenu-router-link';
import {getLoginUserTypeId} from '../../utils/functions.utils'

import MetisMenu from 'react-metismenu';
import _ from 'lodash';
import { SidebarUserbox } from '../../layout-components';

class SidebarMenu extends Component {

  constructor(props) {
    super(props);
    this.state = {
      count_project: this.props.count
    }
    this.sidebarMenuContent = this.setMenuContent(this.props.actions,this.props.road_project_count,this.props.road_qry_count);
    this.menu = React.createRef("menu")
  }

  // data_count = (document.getElementsByClassName("count-my-project").value = this.props.count)
  componentDidMount() {
    if(this.props.actions.MENU.ROAD_MY_PROJECT === true) {
      document.getElementById('count-road-project').innerHTML = this.props.road_project_count ? this.props.road_project_count : 0  ;
    }

    if(this.props.actions.MENU.ROAD_MY_QUERY === true){
      document.getElementById('count-road-query').innerHTML = this.props.road_qry_count ? this.props.road_qry_count : 0;
    }
  }
  componentDidUpdate() {
    if(this.props.actions.MENU.ROAD_MY_PROJECT === true) {
      document.getElementById('count-road-project').innerHTML =  localStorage.getItem("my_project_count") ?  localStorage.getItem("my_project_count") : 0  ;
    }
    if(this.props.actions.MENU.ROAD_MY_QUERY === true){
      document.getElementById('count-road-query').innerHTML = localStorage.getItem("my_query_count") ? localStorage.getItem("my_query_count") : 0;
    }

  }

  setMenuContent = (Action,project_count) => {
    const menuContent = [];
    if (Action.MENU.DASHBOARD) {
      menuContent.push({ "label": "Dashboard", "icon": "fa-light fa-chart-tree-map", "to": "/dashboard"});
    }
    // if (Action.MENU.MANAGE_USERS) {
    //   menuContent.push({ "label": "Manage Users", "icon": "fa-light fa-users", "to": "/manageusers" });
    // }
    if (Action.MENU.MANAGE_LOCAL_ADMIN_USERS) {
      menuContent.push({ "label": "Manage Users", "icon": "fa-light fa-users", "to": "/lausers" });//For Group Manage Users
    }
    if (Action.MENU.COMPANY) {
      menuContent.push({ "label": "Company", "icon": "fa-sharp fa-light fa-building", "to": "/clist" });
    }
    // if (Action.MENU.COMPANY||Action.MENU.COMPANY_UPDATE_LOG || Action.MENU.COMPANY_GROUP) {

    //   let obj = {
    //     "label": "Company",
    //     "icon": "fa-sharp fa-light fa-buildings",
    //     "content": []
    //   };
    //   if (Action.MENU.COMPANY_GROUP) {
    //     obj.content.push({ "label": "Company Group", "to": "/cgroup" });
    //   }
    //   if (Action.MENU.COMPANY) {
    //     obj.content.push({ "label": "Company List", "to": "/clist" });
    //   }
    //   if (Action.MENU.COMPANY_UPDATE_LOG) {
    //     obj.content.push({ "label": "Update Log", "to": "/cupdatelog" });
    //   }
    //   menuContent.push(obj);
    // }
    if (Action.MENU.ROAD_PROJECT_LIST || Action.MENU.ROAD_QUERY || Action.MENU.ROAD_UPDATE_LOG || Action.MENU.ROAD_MY_PROJECT || Action.MENU.ROAD_MY_QUERY || Action.MENU.ROAD_PROJECT_UNPUBLISH) {
      if (Action.MENU.ROAD_PROJECT_LIST) {
        menuContent.push({ "label": "Projects", "icon":"fa-light fa-road", "to": "/rdprojects" });
      }
      // if (Action.MENU.ROAD_PROJECT_UNPUBLISH) {
      //   menuContent.push({ "label":"Projects - Unpublished", "icon":"fa-light fa-ban", "to": "/unpublishproject"});
      // }
      if (Action.MENU.ROAD_QUERY) {
        menuContent.push({ "label": "Query", "icon":"fa-light fa-question-circle", "to": "/rdqry" });
      }
      // if (Action.MENU.ROAD_UPDATE_LOG) {
      //   menuContent.push({ "label": "Update Log", "icon":"fa-light fa-wrench", "to": "/rdupdatelog" });
      // }
      if (Action.MENU.ROAD_MY_PROJECT) {
        menuContent.push({ "label":<>My Projects <span className='count-box' id='count-road-project'></span></>, "icon":"fa-light fa-file", "to": "/myprojectlist"});
      }
      if (Action.MENU.ROAD_MY_QUERY) {
        menuContent.push({ "label": <>My Queries <span className='count-box' id='count-road-query' ></span></>, "icon":"fa-light fa-filter", "to": "/myqrylist"});
      }

    }
      let obj = {
        "label": "Knowledge",
        "icon": "fa-sharp fa-light fa-lightbulb",
        "content": []
      };
      obj.content.push({ "label": "Data Sources", "to": "/datasource" });
      obj.content.push({ "label": "Project Cycle Information", "to": "/cycleinfo" });
      obj.content.push({ "label": "Financial Information", "to": "/financeinfo" });
      obj.content.push({ "label": "Glossary Terms", "to": "/glossary" });
    menuContent.push(obj);
    return menuContent;
  }


  activeLinkMenu = () => {

  }
  
  render() {
   
    return (
        <Fragment>
          <PerfectScrollbar>
            <div className="sidebar-navigation">
                <MetisMenu ref={this.menu} activeLinkTo={this.props.activeLinkToMenu}  content={this.sidebarMenuContent} LinkComponent={RouterLink} activeLinkFromLocation iconNamePrefix="" noBuiltInClassNames={true}
                classNameItemActive="active" classNameIcon="sidebar-icon" iconNameStateVisible="sidebar-icon-indicator" iconNameStateHidden="sidebar-icon-indicator"
                classNameItemHasVisibleChild="submenu-open" classNameItemHasActiveChild="active" classNameStateIcon="fa fa-caret-down" />
            </div>
          </PerfectScrollbar>
      </Fragment>
      );
    }
}


const mapStateToProps = state => ({
  actions: _.has(state.Authentication, 'user.action') ? state.Authentication.user.action : {},
  sidebarUserbox: state.ThemeOptions.sidebarUserbox,
  activeLinkToMenu: state.ThemeOptions.activeLinkTo || '',

});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);
