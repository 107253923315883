import React, { Component, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import configureStore from './config/configureStore';
import {Provider} from 'react-redux';
import Routes from './Routes';
import ScrollToTop from './utils/ScrollToTop';
import { ToastContainer, toast } from 'react-toastify';
import "./assets/base.scss";
import './assets/favicon/css/fontawesome.css';
import 'react-notifications/lib/notifications.css';
import packageJson from '../package.json';


import { library } from '@fortawesome/fontawesome-svg-core';
import { fab, faFacebook, faTwitter, faVuejs, faReact, faHtml5, faGoogle, faInstagram, faPinterest, faYoutube, faDiscord, faSlack, faDribbble, faGithub } from '@fortawesome/free-brands-svg-icons';
import { far, faSquare, faLifeRing, faCheckCircle, faTimesCircle, faDotCircle, faThumbsUp, faComments, faFolderOpen, faTrashAlt, faFileImage, faFileArchive, faCommentDots, faFolder, faKeyboard, faCalendarAlt, faEnvelope, faAddressCard, faMap, faObjectGroup, faImages, faUser, faLightbulb, faGem, faClock, faUserCircle, faQuestionCircle, faBuilding, faBell, faFileExcel, faFileAudio, faFileVideo, faFileWord, faFilePdf, faFileCode, faFileAlt, faEye, faChartBar } from '@fortawesome/free-regular-svg-icons';
import { fas, faAngleDoubleRight, faAngleDoubleLeft, faSmile, faHeart, faBatteryEmpty, faBatteryFull, faChevronRight, faSitemap, faPrint, faMapMarkedAlt, faTachometerAlt, faAlignCenter, faExternalLinkAlt, faShareSquare, faInfoCircle, faSync, faQuoteRight, faStarHalfAlt, faShapes, faCarBattery, faTable, faCubes, faPager,  faCameraRetro, faBomb, faNetworkWired, faBusAlt, faBirthdayCake, faEyeDropper, faUnlockAlt, faDownload, faAward, faPlayCircle, faReply, faUpload, faBars, faEllipsisV, faSave, faSlidersH, faCaretRight, faChevronUp, faPlus, faLemon, faChevronLeft, faTimes, faChevronDown, faFilm, faSearch, faEllipsisH, faCog, faArrowsAltH, faPlusCircle, faAngleRight, faAngleUp, faAngleLeft, faAngleDown, faArrowUp, faArrowDown, faArrowRight, faArrowLeft, faStar, faSignOutAlt, faLink } from '@fortawesome/free-solid-svg-icons';
library.add(far, faSquare, faLifeRing, faCheckCircle, faTimesCircle, faDotCircle, faThumbsUp, faComments, faFolderOpen, faTrashAlt, faFileImage, faFileArchive, faCommentDots, faFolder, faKeyboard, faCalendarAlt, faEnvelope, faAddressCard, faMap, faObjectGroup, faImages, faUser, faLightbulb, faGem, faClock, faUserCircle, faQuestionCircle, faBuilding, faBell, faFileExcel, faFileAudio, faFileVideo, faFileWord, faFilePdf, faFileCode, faFileAlt, faEye, faChartBar);
library.add(fab, faFacebook, faTwitter, faVuejs, faReact, faHtml5, faGoogle, faInstagram, faPinterest, faYoutube, faDiscord, faSlack, faDribbble, faGithub);
library.add(fas, faAngleDoubleRight, faAngleDoubleLeft, faSmile, faHeart, faBatteryEmpty, faBatteryFull, faChevronRight, faSitemap, faPrint, faMapMarkedAlt, faTachometerAlt, faAlignCenter, faExternalLinkAlt, faShareSquare, faInfoCircle, faSync, faQuoteRight, faStarHalfAlt, faShapes, faCarBattery, faTable, faCubes, faPager,  faCameraRetro, faBomb, faNetworkWired, faBusAlt, faBirthdayCake, faEyeDropper, faUnlockAlt, faDownload, faAward, faPlayCircle, faReply, faUpload, faBars, faEllipsisV, faSave, faSlidersH, faCaretRight, faChevronUp, faPlus, faLemon, faChevronLeft, faTimes, faChevronDown, faFilm, faSearch, faEllipsisH, faCog, faArrowsAltH, faPlusCircle, faAngleRight, faAngleUp, faAngleLeft, faAngleDown, faArrowUp, faArrowDown, faArrowRight, faArrowLeft, faStar, faSignOutAlt, faLink);

const store = configureStore();

class App extends Component {

autoLogout=()=>{
    if(localStorage.getItem('version')===null){
      localStorage.clear()
      window.location.reload()
      localStorage.setItem("version" , packageJson.version.toString())
  }else{
    if(localStorage.getItem('version')!==packageJson.version){
      localStorage.clear()
      window.location.reload()
      localStorage.setItem("version" , packageJson.version.toString())
    }
  }
  }

  componentDidMount() {
    if (localStorage.getItem('version') == null) {
      this.autoLogout()
    }

    window.addEventListener("storage", function (e) {
      if (localStorage.getItem('version') === null) {
        localStorage.clear()
        window.location.reload()
        localStorage.setItem("version", packageJson.version.toString())
      } else {
        if (localStorage.getItem('version') !== packageJson.version) {
          localStorage.clear()
          window.location.reload()
          localStorage.setItem("version", packageJson.version.toString())
        }
        if(localStorage.getItem('user_type_id') === null || localStorage.getItem('user_type_id')==="" || localStorage.getItem('user_type_id') === undefined ){
          localStorage.clear()
          window.location.reload()
          localStorage.setItem("version" , packageJson.version.toString())
      }
      }
    })


  }

  render() {


    // localStorage.setItem("version" , packageJson.version.toString())
    // if(localStorage.getItem("version") !==  packageJson.version){
    //   localStorage.clear()
    //   window.location.href = "/#"
    // }
    // if(packageJson.api_url.includes('https')){
    //   console.log=()=>{}
    // }

    return (
        <Provider store={store}>
            <BrowserRouter>
                <ScrollToTop>
                    <Routes />
                </ScrollToTop>
                <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
                <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
                <ToastContainer enableMultiContainer containerId={'C'} position={toast.POSITION.BOTTOM_CENTER} />
            </BrowserRouter>
        </Provider>
    );
  }
}

export default App;
