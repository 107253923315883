import React, { Component, Fragment, useState, useMemo, useEffect } from 'react';
import { connect } from "react-redux";
import { Button, UncontrolledTooltip, Row, Col, Card, CardBody, Label, InputGroup, Input, InputGroupAddon, Tooltip, CardHeader, CardFooter, Spinner, Modal, ButtonGroup } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Pagination } from 'components';
import { fnRoadProjectActions } from "actions";
import { PROJECT_STATUS_UNDER_BIDDING, PROJECT_MODE, SET_ROAD_PROJECT_DATA_FROM_PLIST_FOR_LSTORE } from "../../constants";
import _, { slice } from 'lodash';
import moment from "moment";
import cx from 'classnames';
import { Finance, ProjectViewLoader,AjaxLoader } from "components";
import { useTable, usePagination, useGlobalFilter } from 'react-table';
import Select, { Option } from 'rc-select';
import AvailableProject from "../../components/Company/Projectform";
import { SessionData, toEncryption, Request, toDecryption, SweetAlert } from 'utils';
import UploadFileCard from './Uploadfile';
import MapView from './MapView';

class ProjectsView extends Component {

    state = {
        isOpenFinance: false,
        m_company_id: 0,
        isSlider: false,
        available: [],
        available_data: [],
        name: "",
        company:{},
        export_id:"",
        export_group_id:"",
        tollMarker:[],
        facilities:[],
        getData:[],
        stretch:[],
        mapLoading:false,
        historyShow:false,
        loadButton:false,
        height: 0,
        showTollPlaze: true,
        rSelected: 1
    }

    constructor(props) {
        super(props);
        this.projectId = _.has(this.props, 'projectId') ? this.props.projectId : 0;
        this.history=React.createRef()
        this.sourceRef = React.createRef();
    }


    componentDidMount() {
        // this.props.getProjectDetails(this.props.projectId);
        this.props.getProjectDetails(this.props.projectId).then((res) => {
            this.setState({mapLoading:true})
            SessionData.set(SET_ROAD_PROJECT_DATA_FROM_PLIST_FOR_LSTORE, toEncryption(res.meta.data, true));
        })

        // console.log(this.props)
            
        if(!window.location.pathname.includes("/rdqry")){
            this.getRoadStretch()

        }else{

        }
    
        this.getToll()

        Request.post('getFacilities',{id_road_project: this.projectId},2).then(res => {
            let data = _.get(res, 'meta.data');
            this.setState({facilities:data})
        }).catch(err => {
            //Get Error
        });

        Request.post("getRoadDoc",{id_road_project: this.projectId},2).then((res)=>{
            if(res.meta['data']){
              this.setState({getData:res.meta.data})
            }else{
              this.setState({getData:[]})
            }
        })
    }

    componentDidUpdate(prevProps) {
        if(prevProps.finance !== this.props.finance && this.props.finance) {
            this.viewFinanceData(this.props.projectData.company_detail.m_company_id)
        }
    }
    getToll=()=>{
            Request.post("getRoadToll", {id_road_project:this.projectId}, 2).then((res) => {
                if(res.meta['data']){
                    res.meta.data.forEach((e)=>{
                        return e.m_toll_facitilites_id=e.m_toll_facitilites_id===null?[]:JSON.parse(e.m_toll_facitilites_id??'[]').map((s)=>s),
                                e.open_date=Number(e.open_date)
                    })
                    this.setState({tollMarker: res.meta.data })
                }

            })
    }

    getRoadStretch=()=>{
        Request.post('getRoadStretch',{id_road_project:this.projectId},2).then((res)=>{
            if(res.meta['data']){
                if(res.meta.data.length>0){
                this.setState({stretch:res.meta.data})
                }
            }
            })
    }

    viewFinanceData = (m_company_id) => {
        this.setState({
            isOpenFinance: true,
            m_company_id: m_company_id
        });
    }

    expandToggle = (e) => {
        if (e === 1) {
            this.setState({
                showTollPlaze: true,
                rSelected: 1
            });
        } else {
            this.setState({
                showTollPlaze: false,
                rSelected: 2
            });
        }
        this.props.refMap.current.scrollIntoView({ behavior: "smooth" })
    }

    closeFinanceData = (e) => {
        e.preventDefault();
        this.setState({
            isOpenFinance: false,
            m_company_id: 0
        });
    }

    sliderOpenDev = (values) => {
        this.setState({isSlider:true})
        let data = {}
        this.setState({ available:[]})
        this.setState({ available_data:[]})
        this.setState({ ctype:""})
        if ('m_company_type_id' in values && +values.m_company_type_id === 1) {
            this.setState({company:values, export_id:values.m_company_id, export_group_id:""})
            data = {
                m_company_id: values.m_company_id
            }
        } else {
            this.setState({company:values, export_id:values.m_company_id, export_group_id:""})
            data = {
                m_company_id_parent: values.m_company_id
            }
        }
        Request.post('getQrySearchResults', data, 2).then((res) => {
            this.setState({ available: res.meta.data.rows ?? [] })
            this.setState({available_data:res.meta.data.company_dashboard})
            // if (this.state.available.length > 0) {
            //     this.setState({isSlider:false, sliderShow: true })
            // }
            if (res.meta.data.rows && res.meta.data.rows.length > 0) {
                this.setState({ isSlider:false, sliderShow: true })
            }
        })

    }

    sliderOpenSPV = (values) => {
        let data = {}
        this.setState({ available:[]})
        this.setState({ available_data:[]})
        this.setState({ ctype:""})
        if ('m_company_type_id' in values && +values.m_company_type_id === 1) {
            this.setState({company:values})
            data = {
                m_company_id: values.m_company_id
            }
        } else {
            this.setState({company:values})
            data = {
                m_company_id_parent: values.m_company_id
            }
        }
        Request.post('getQrySearchResults', data, 2).then((res) => {
            this.setState({ available: res.meta.data.rows ?? [] })
            this.setState({available_data:res.meta.data.company_dashboard})
            // if (this.state.available.length > 0) {
            //     this.setState({ sliderShow: true})
            // }
            if (res.meta.data.rows && res.meta.data.rows.length > 0) {
                this.setState({ sliderShow: true })
            }
        })

    }

    groupSlider = (values) => {
        this.setState({isSlider:true})
    //    values.company_name = values.company_group_name
        this.setState({ available:[], available_data:[], ctype: "", company:values, export_group_id:values.m_company_group_id,  export_id:""})
        let data = {
            m_company_group_id: values.m_company_group_id
        }
        Request.post('getQrySearchResults', data, 2).then((res) => {
            this.setState({ available: res.meta.data.rows ?? [] })
            this.setState({available_data:res.meta.data.company_dashboard})
            this.setState({ctype: 'group'})
            // if (this.state.available.length > 0) {
            //     this.setState({isSlider:false, sliderShow: true })
            // }
            if (res.meta.data.rows && res.meta.data.rows.length > 0) {
                this.setState({ isSlider:false, sliderShow: true })
            }
        })

    }

    closeSlider = () => {
        this.setState({
            sliderShow: false,
            isOpenFinance: false
        });
    }

    resAvailDataExportEvent = (e) => {
        this.setState({ excel: true })
        const data = { globalFilter: e,m_company_group_id:this.state.export_group_id,m_company_id_parent:this.state.export_id};
        this.props.downloadExcelFileData(data, `road_data_${moment().format('DD_MMM_YYYY')}_${moment.now()}.xls`);
        this.setState({ excel: false })
    }

    static CardLabelContents(props,) {
        const { label, labelTitle, children ,style,title,classname} = props;

        return (<><small  className={classname} data-title={title} style={style} >{label}</small><div className="text-dark">{children}</div></>);
    }

    static ProjectInfoCard(props) {
        const { access,projectData,window_url, viewFinanceData, m_company_id, closeFinanceData, showInPopup, projectData: { company_detail = {} }, heightData, highLightValues, sliderOpenDev,sliderOpenSPV,groupSlider } = props;

        let showInpopup = false;
        if (!_.isNil(showInPopup) && showInPopup == true) {

            if (window.outerWidth >= 1200 && window.outerWidth <= 1440) {
                showInpopup = true;
            }

        }

        return (
            <Card className="card-list-box project-view-details-card project-list-card">
                <CardHeader>
                 <div className='d-flex justify-content-between'>
                        <span className='text-dark title'>Project & Company Info</span>
                        <div className='' style={{fontWeight:500,textTransform:"capitalize", color: projectData.project_status_color ? projectData.project_status_color : ''}}>
                            {projectData.project_status}
                        </div>
                    </div>
                </CardHeader>
                <CardBody className="pt-0 pb-2">
                    <Row>
                        <Col md={3} className="pr-2">
                            <Row className="project-view-details-card">
                            {/* <Col md={12}>
                                        <small className={`font-weight-light ${highLightValues.includes("company_m_company_id") && "data-title"}`} data-title={highLightValues.includes("company_m_company_id")?heightData.filter((e)=>e.id==="company_m_company_id").map((e)=>{
                                return `Previous Value : \n${e.old_value}`
                          }):'Type Of ompany'} >Developer Name</small>{access.ALLOW_SHOW_OTHER_PROJECT && !window_url.includes("rdqry")  ?
                          <div  className='text-dark'>
                            {_.has(projectData, 'company_detail.parent_company_details') ? projectData.company_detail.parent_company_details.map((e,i)=>   <div  onClick={() => sliderOpenDev(e)}style={{  cursor: 'pointer', color: "#3062d5",fontSize:"13px",fontWeight:600 }}>{e.company_name}{i===projectData.company_detail.parent_company_details.length-1  ?"": projectData.company_detail.parent_company_details.length===1?"":','}</div> ) : '-'}</div> :
                            <div className='text-dark'>
                            {_.has(projectData, 'company_detail.parent_company_name') ? projectData.company_detail.parent_company_name : '-'}</div>}

                                    </Col> */}
                                    {_.get(company_detail, 'm_company_type_id') == 2 ? (
                                        <>
                                          <Col md={12}>
                                    <small className={`font-weight-light ${highLightValues.includes("company_m_company_id") && "data-title"}`} data-title={highLightValues.includes("company_m_company_id")?heightData.filter((e)=>e.id==="company_m_company_id").map((e)=>{
                                            return `Previous Value : \n${e.old_value}`
                                    }):'Developer Name'} >Developer Name</small>{access.ALLOW_SHOW_OTHER_PROJECT && !window_url.includes("rdqry")  ?
                                    <div role='button'  className='text-dark title'>
                                        {_.has(projectData, 'company_detail.parent_company_details') ? projectData.company_detail.parent_company_details.map((e,i)=><div  onClick={() => sliderOpenDev(e)} style={{}}>{e.company_name}{i===projectData.company_detail.parent_company_details.length-1  ?"": projectData.company_detail.parent_company_details.length===1?"":','}</div> ) : '-'}</div> :
                                        <div className='text-dark'>
                                        {_.has(projectData, 'company_detail.parent_company_name') ? projectData.company_detail.parent_company_name : '-'}</div>}
                                    </Col>
                                    <Col md={12}>
                                        <Row>
                                            <Col md={12}>
                                                <small className={`font-weight-light ${highLightValues.includes("") && "data-title"}`} title={'SPV Name'}>SPV Name</small>
                                                <div className="text-dark">
                                                    {_.has(projectData, 'company_detail.company_name') ? projectData.company_detail.company_name : '-'}
                                                </div>
                                            </Col>
                                            {/* {
                                                    _.has(projectData, 'company_detail.is_finance_available') && +projectData.company_detail.is_finance_available ?
                                                        (<Col md={2} className="pl-0">

                                                            {props.isOpenFinance ? (
                                                                <Fragment>
                                                                    <Button onClick={e => {e.preventDefault(); closeFinanceData(e); }} type="button" size="sm" outline color="danger" id="view_finance" style={{ padding: '0.15rem 0.5rem' }}><FontAwesomeIcon icon={['fas', 'times']} /></Button>
                                                                    <UncontrolledTooltip popperClassName={'tooltip-danger'} placement="top" target="view_finance">
                                                                        Close Finance
                                                                    </UncontrolledTooltip>
                                                                </Fragment>
                                                            ) : (
                                                                <Fragment>
                                                                    <Button onClick={e => { e.preventDefault(); viewFinanceData(projectData.company_detail.m_company_id); }} type="button" size="sm" outline color="info" id="view_finance" style={{ padding: '0.15rem 0.5rem' }}><FontAwesomeIcon icon={['fas', 'rupee-sign']} /> </Button>
                                                                    <UncontrolledTooltip popperClassName={'tooltip-info'} placement="top" target="view_finance">
                                                                        View Finance
                                                                    </UncontrolledTooltip>
                                                                </Fragment>
                                                            )}

                                                        </Col>) : null
                                                } */}
                                        </Row>
                                    </Col>
                                  
                                    </>
                                ) : (
                                    <Col md={12}>
                                        <small className={`font-weight-light ${highLightValues.includes("company_m_company_id") && "data-title"}`} data-title={highLightValues.includes("company_m_company_id")?heightData.filter((e)=>e.id==="company_m_company_id").map((e)=>{
                                return `Previous Value : \n${e.old_value}`
                          }):'Developer'} >Developer Name</small><div >
                                            <Row>
                                            {access.ALLOW_SHOW_OTHER_PROJECT && !window_url.includes("rdqry") ?
                                                <Col md={12} className="text-dark title" role='button' onClick={() => sliderOpenDev(projectData.company_detail)}>
                                                    {_.has(projectData, 'company_detail.company_name') ?
                                                        projectData.company_detail.company_name :
                                                        '-'}
                                                </Col>:
                                                <Col md={12} className='text-dark' >
                                                {_.has(projectData, 'company_detail.company_name') ?
                                                    projectData.company_detail.company_name :
                                                    '-'}
                                                </Col>}
                                            </Row>
                                        </div>
                                    </Col>
                                )}
                            <Col md={12}>
                                    <ProjectsView.CardLabelContents classname={`font-weight-light ${highLightValues.includes("road_unique_id") && "data-title"}`}  label={'Project Unique Id'} title={highLightValues.includes("road_unique_id")?heightData.filter((e)=>e.id==="road_unique_id").map((e)=>{
                                return `Previous Value : \n${e.old_value}`
                          }):'Project Unique Id'} >{projectData.road_unique_id ? projectData.road_unique_id : '-'}</ProjectsView.CardLabelContents>
                                </Col>
                                <Col md={12}>
                                    <small className={`font-weight-light ${highLightValues.includes("scheme") && "data-title"}`} data-title={highLightValues.includes("scheme")?heightData.filter((e)=>e.id==="scheme").map((e)=>{
                                return `Previous Value : \n${e.old_value}`
                          }):'Scheme'} >Scheme</small><div className="text-dark">{projectData.scheme || '-'}</div>
                                </Col>
                                <Col md={12}>
                                    <small className={`font-weight-light ${highLightValues.includes("m_company_type_id") && "data-title"}`} data-title={highLightValues.includes("m_company_type_id")?heightData.filter((e)=>e.id==="m_company_type_id").map((e)=>{
                                        return `Previous Value : \n${e.old_value}`
                                }):'Type Of ompany'}  >Type Of Company</small>
                                    <div className="text-dark" >{_.size(company_detail) && _.get(company_detail, 'm_company_type_id') > 0 ? (_.get(company_detail, 'm_company_type_id') == 1 ? 'Developer' : 'SPV') : '-'}</div>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={3} className="px-2">
                            <Row className="project-view-details-card">
                                <Col md={12}>
                                    <ProjectsView.CardLabelContents classname={`font-weight-light ${highLightValues.includes("m_state_id") && "data-title"}`}  label={'State'} title={highLightValues.includes("m_state_id")?heightData.filter((e)=>e.id==="m_state_id").map((e)=>{
                                                return `Previous Value : \n${e.old_value}`
                                        }):'State'} >{_.size(projectData.state_name) ? _.join(projectData.state_name, ', ') : '-'}</ProjectsView.CardLabelContents>
                                </Col>
                                <Col md={12}>
                                        <small className='font-weight-light'>CIN #</small><div className="text-dark">{_.has(projectData, 'company_detail.cin_no') ? projectData.company_detail.cin_no : '-'}</div>
                                    </Col>
                                <Col md={12}>
                                    <small className={`font-weight-light ${highLightValues.includes("nh_sh_no") && "data-title"}`} data-title={highLightValues.includes("nh_sh_no")?heightData.filter((e)=>e.id==="nh_sh_no").map((e)=>{
                                return `Previous Value : \n${e.old_value}`
                          }):'NH/SH Number'} >NH/SH Number</small><div className="text-dark">{projectData.nh_sh_no || '-'}</div>
                                </Col>
                                <Col md={12}>
                                    <small className={`font-weight-light ${highLightValues.includes("number_lane") && "data-title"}`} data-title={highLightValues.includes("number_lane")?heightData.filter((e)=>e.id==="number_lane").map((e)=>{
                                return `Previous Value : \n${e.old_value}`
                          }):'Number Of Lane'}  >Number Of Lane</small><div className="text-dark">{projectData.number_lane || '-'}</div>
                                </Col>
                                <Col md={12}>
                                    <small className={`font-weight-light ${highLightValues.includes("m_mode_id") && "data-title"}`} data-title={highLightValues.includes("m_mode_id")?heightData.filter((e)=>e.id==="m_mode_id").map((e)=>{
                                return `Previous Value : \n${e.old_value}`
                          }):'Mode'}  >Mode</small><div className="text-dark">
                                        {projectData.project_mode}{(PROJECT_MODE.PPP === +projectData.m_mode_id && !_.isEmpty(projectData.mode_ppp)) ? " - " + projectData.mode_ppp : null}
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={3} className="px-2">
                            <Row className="project-view-details-card">
                                <Col md={12}>
                                        <ProjectsView.CardLabelContents classname={`font-weight-light ${highLightValues.includes("m_district_id") && "data-title"}`} label={'District'} title={highLightValues.includes("m_district_id")?heightData.filter((e)=>e.id==="m_district_id").map((e)=>{
                                    return `Previous Value : \n${e.old_value}`
                            }):'district'}>{_.size(projectData.district_name) ? _.join(projectData.district_name, ', ') : '-'}</ProjectsView.CardLabelContents>
                                    </Col>
                                    <Col md={12}>
                                    <small className={`font-weight-light ${highLightValues.includes("nh_sh") && "data-title"}`} data-title={highLightValues.includes("nh_sh")?heightData.filter((e)=>e.id==="nh_sh").map((e)=>{
                                return `Previous Value : \n${e.old_value}`
                          }):'NH/SH'} >NH/SH</small><div className="text-dark">{projectData.nh_sh_name || '-'}</div>
                                </Col>
                                    <Col md={12}>
                                    <small className={`font-weight-light ${highLightValues.includes("nhdp_phase") && "data-title"}`} data-title={highLightValues.includes("nhdp_phase")?heightData.filter((e)=>e.id==="nhdp_phase").map((e)=>{
                                return `Previous Value : \n${e.old_value}`
                          }):'Phase'} >Phase</small><div className="text-dark">{projectData.nhdp_phase || '-'}</div>
                                </Col>
                                <Col md={12}>
                                    <small className="font-weight-light" >Group</small>{access.ALLOW_SHOW_OTHER_PROJECT && !window_url.includes("rdqry") ?
                                    <div className='text-dark title' role='button' onClick={() => groupSlider(projectData.company_detail)}>
                                        {projectData?.company_detail?.company_group_name ? projectData.company_detail.company_group_name : '-'}</div> :
                                            <div className='text-dark'>
                                            {projectData?.company_detail?.company_group_name ? projectData.company_detail.company_group_name : '-'}</div>}
                                </Col>
                            </Row>
                        </Col>
                        <Col md={3} className="px-2">
                            <Row className="project-view-details-card">
                            <Col md={12}>
                                    <ProjectsView.CardLabelContents classname={`font-weight-light ${highLightValues.includes("road_package_name") && "data-title"}`}  label={'Package'} title={highLightValues.includes("road_package_name")?heightData.filter((e)=>e.id==="road_package_name").map((e)=>{
                                return `Previous Value : \n${e.old_value}`
                          }):'Stretch'} >{_.has(projectData,"road_package_name") ? projectData.road_package_name??'-' : '-'}</ProjectsView.CardLabelContents>
                                </Col>
                                <Col md={12}>
                                    <small className={`font-weight-light ${highLightValues.includes("authority") && "data-title"}`} data-title={highLightValues.includes("authority")?heightData.filter((e)=>e.id==="authority").map((e)=>{
                                return `Previous Value : \n${e.old_value}`
                          }):'Authority'}  >Authority</small><div className="text-dark">{projectData.authority || '-'}</div>
                                </Col>
                                <Col md={12}>
                                    <small className={`font-weight-light ${highLightValues.includes("stretch_length") && "data-title"}`} data-title={highLightValues.includes("stretch_length")?heightData.filter((e)=>e.id==="stretch_length").map((e)=>{
                                return `Previous Value : \n${e.old_value}`
                          }):'Stretch (Km)'} >Stretch (Km)</small><div className="text-dark">{projectData.stretch_length || '-'}</div>
                                </Col>
                                <Col md={12}>
                                    <small className={`font-weight-light ${highLightValues.includes("lane_km") && "data-title"}`} data-title={highLightValues.includes("lane_km")?heightData.filter((e)=>e.id==="lane_km").map((e)=>{
                                return `Previous Value : \n${e.old_value}`
                          }):'Phase'} >Lane (Km)</small><div className="text-dark">{projectData.lane_km || '-'}</div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    {/* {
                        _.has(projectData, 'company_detail.is_finance_available') && +projectData.company_detail.is_finance_available ?
                        (<div className="finance-btn">

                            {props.isOpenFinance ? (
                                <Fragment>
                                    <Button onClick={e => {e.preventDefault(); closeFinanceData(e); }} type="button" size="sm" color="link" id="view_finance" style={{ padding: '0.15rem 0.5rem' }}>
                                        <div className='btndanger'>
                                        <FontAwesomeIcon icon={['fas', 'times']} />
                                        </div>
                                    </Button>
                                    <UncontrolledTooltip popperClassName={'tooltip-danger'} placement="top" target="view_finance">
                                        Close Finance
                                    </UncontrolledTooltip>
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <Button onClick={e => { e.preventDefault(); viewFinanceData(projectData.company_detail.m_company_id); }} type="button" size="sm" color="link" id="view_finance" style={{ padding: '0.15rem 0.5rem' }}>
                                        <div className='btninfo'>
                                        <FontAwesomeIcon icon={['fas', 'rupee-sign']} className='m-0' /> </div>
                                    </Button>
                                    <UncontrolledTooltip popperClassName={'tooltip-info'} placement="top" target="view_finance">
                                        View Finance
                                    </UncontrolledTooltip>
                                </Fragment>
                            )}
                        </div>) : null
                    } */}
                    
                <hr className='m-0' style={{borderTop: '1px solid #E3E3E3'}} />
                </CardBody>
                <CardHeader>
                    <div className='d-flex justify-content-between'>
                        <span className='text-dark title'>Financial Info (Rs.Cr)</span>
                    </div>
                </CardHeader>
                <CardBody className="pt-0">
                <Row>
                        {/***<Col sm={12} md={12}>
                            <small className={`font-weight-light ${highLightValues.includes("") && "data-title"}`} title={'Banks'}>Banks</small><div className="text-dark">{_.size(projectData.bank_name) ? _.join(projectData.bank_name, ', ') : '-'}</div>
                        </Col>***/}
                        <Col sm={3} md={3}>
                            <small className={`font-weight-light ${highLightValues.includes("tpc") && "data-title"}`} data-title={highLightValues.includes("tpc")?heightData.filter((e)=>e.id==="tpc").map((e)=>{
                                return `Previous Value : \n${e.old_value}`
                          }):'Total Project Cost'} >Total Project Cost</small><div className="text-dark">{projectData.tpc ? projectData.tpc : '-'}</div>
                        </Col>
                        <Col sm={3} md={3}>
                            <small className={`font-weight-light ${highLightValues.includes("rpc") && "data-title"}`} data-title={highLightValues.includes("rpc")?heightData.filter((e)=>e.id==="rpc").map((e)=>{
                                return `Previous Value : \n${e.old_value}`
                          }):'Revised Project Cost'} >Revised Project Cost</small><div className="text-dark">{projectData.rpc ? projectData.rpc : '-'}</div>
                        </Col>
                        <Col sm={3} md={3}>
                            <small className={`font-weight-light ${highLightValues.includes("dpc") && "data-title"}`} data-title={highLightValues.includes("dpc")?heightData.filter((e)=>e.id==="dpc").map((e)=>{
                                return `Previous Value : \n${e.old_value}`
                          }):'Developer Cost'} >Developer Cost</small><div className="text-dark">{projectData.dpc ? projectData.dpc : '-'}</div>
                        </Col>
                        <Col sm={3} md={3}>
                            <small className={`font-weight-light ${highLightValues.includes("equity") && "data-title"}`} data-title={highLightValues.includes("equity")?heightData.filter((e)=>e.id==="equity").map((e)=>{
                                return `Previous Value : \n${e.old_value}`
                          }):'Equity'} >Equity</small><div className="text-dark">{projectData.equity ? projectData.equity : '-'}</div>
                        </Col>
                        <Col sm={3} md={3}>
                            <small className={`font-weight-light ${highLightValues.includes("debt") && "data-title"}`} data-title={highLightValues.includes("debt")?heightData.filter((e)=>e.id==="debt").map((e)=>{
                                return `Previous Value : \n${e.old_value}`
                          }):'Debt'} >Debt</small><div className="text-dark">{projectData.debt ? projectData.debt : '-'}</div>
                        </Col>
                        <Col sm={3} md={3}>
                            <small className={`font-weight-light ${highLightValues.includes("amount_annuity") && "data-title"}`} data-title={highLightValues.includes("amount_annuity")?heightData.filter((e)=>e.id==="amount_annuity").map((e)=>{
                                return `Previous Value : \n${e.old_value}`
                          }):'Annuity'}>Annuity</small><div className="text-dark">{projectData.amount_annuity ? projectData.amount_annuity : '-'}</div>
                        </Col>
                        <Col sm={3} md={3}>
                            <small className={`font-weight-light ${highLightValues.includes("annuity_percentage") && "data-title"}`} data-title={highLightValues.includes("annuity_percentage")?heightData.filter((e)=>e.id==="annuity_percentage").map((e)=>{
                                return `Previous Value : \n${e.old_value}`
                          }):'Annuity (%)'}>Annuity (%)</small><div className="text-dark">{projectData.annuity_percentage ? projectData.annuity_percentage : '-'}</div>
                        </Col>
                        {/* <Col sm={3} md={3}>
                            <small className={`font-weight-light ${highLightValues.includes("premium") && "data-title"}`} data-title={highLightValues.includes("premium")?heightData.filter((e)=>e.id==="premium").map((e)=>{
                                return `Previous Value : \n${e.old_value}`
                          }):'Premium'}>Premium</small><div className="text-dark">{projectData.premium ? projectData.premium : '-'}</div>
                        </Col> */}
                        <Col sm={3} md={3}>
                            <small className={`font-weight-light ${highLightValues.includes("amount_grant") && "data-title"}`} data-title={highLightValues.includes("amount_grant")?heightData.filter((e)=>e.id==="amount_grant").map((e)=>{
                                return `Previous Value : \n${e.old_value}`
                          }):'Grant'}>Grant</small><div className="text-dark">{projectData.amount_grant ? projectData.amount_grant : '-'}</div>
                        </Col>
                        <Col sm={3} md={3}>
                            <small className={`font-weight-light ${highLightValues.includes("grant_percentage") && "data-title"}`} data-title={highLightValues.includes("grant_percentage")?heightData.filter((e)=>e.id==="grant_percentage").map((e)=>{
                                return `Previous Value : \n${e.old_value}`
                          }):'Grant (%)'}>Grant (%)</small><div className="text-dark">{projectData.grant_percentage ? projectData.grant_percentage : '-'}</div>
                        </Col>
                        <Col sm={3} md={3}>
                            <small className={`font-weight-light ${highLightValues.includes("negative_grant") && "data-title"}`} data-title={highLightValues.includes("negative_grant")?heightData.filter((e)=>e.id==="negative_grant").map((e)=>{
                                return `Previous Value : \n${e.old_value}`
                          }):'Negative Grant'}>Negative Grant</small><div className="text-dark">{projectData.negative_grant ? projectData.negative_grant : '-'}</div>
                        </Col>
                        <Col sm={3} md={3}>
                            <small className={`font-weight-light ${highLightValues.includes("tpc_per_km") && "data-title"}`} data-title={highLightValues.includes("tpc_per_km")?heightData.filter((e)=>e.id==="tpc_per_km").map((e)=>{
                                return `Previous Value : \n${e.old_value}`
                          }):'Total Project Cost/KM'}>Total Project Cost/KM</small><div className="text-dark">{projectData.tpc_per_km ? projectData.tpc_per_km : '-'}</div>
                        </Col>
                        <Col sm={3} md={3}>
                            <small className={`font-weight-light ${highLightValues.includes("long_term_loan") && "data-title"}`} data-title={highLightValues.includes("long_term_loan")?heightData.filter((e)=>e.id==="long_term_loan").map((e)=>{
                                return `Previous Value : \n${e.old_value}`
                          }):'Long Term Loan'}>Long Term Loan</small><div className="text-dark">{projectData.long_term_loan ? projectData.long_term_loan : '-'}</div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        );
    }

    static CompanyInfoCard(props) {
        const { access,projectData,window_url, viewFinanceData, m_company_id, closeFinanceData, showInPopup, projectData: { company_detail = {} }, heightData, highLightValues, sliderOpenDev,sliderOpenSPV,groupSlider } = props;

        return (
            <Card className="card-box mb-3 rounded-0 border-0 card-list-box project-view-details-card">
                <CardBody>
                    <div className="text-left mb-2" style={{ backgroundColor: '#f7f7f7' }}>
                        <div className="mb-0 text-black py-1 px-1 project-name" style={{ fontSize: '0.83rem' }}>Company Info</div>
                    </div>
                    <Row>
                        <Col md={12}>
                            <small className={`font-weight-light ${highLightValues.includes("m_company_type_id") && "data-title"}`} data-title={highLightValues.includes("m_company_type_id")?heightData.filter((e)=>e.id==="m_company_type_id").map((e)=>{
                                return `Previous Value : \n${e.old_value}`
                          }):'Type Of ompany'}  >Type Of Company</small>
                            <div className="text-dark" >{_.size(company_detail) && _.get(company_detail, 'm_company_type_id') > 0 ? (_.get(company_detail, 'm_company_type_id') == 1 ? 'Developer' : 'SPV') : '-'}</div>
                        </Col>
                    </Row>
                    {/***PROJECT_MODE.PPP === +projectData.m_mode_id ? (***/}
                </CardBody>
            </Card>
        );
    }

    static BiddingInfoCard(props) {
        const { projectData ,highLightValues,heightData} = props;
        
        return (
            <>
            <Card className="card-box card-list-box mb-3 border-0 project-view-details-card project-list-card project-list-card">
                <CardHeader>
                 <div className='d-flex justify-content-between'>
                    <span className='text-dark title'>Events/Bidding Info</span>
                </div>
                </CardHeader>
                <CardBody className="pt-0">
                <div className='d-flex w-100'>
                    <div className='d-flex flex-column w-100'>
                        <Row>
                            <Col sm={3}>
                                <small className={`font-weight-light ${highLightValues.includes("concession_period") && "data-title"}`} data-title={highLightValues.includes("concession_period")?heightData.filter((e)=>e.id==="concession_period").map((e)=>{
                                    return `Previous Value : \n${e.old_value}`
                            }):'Concession Period (Years)'}>Concession Period (Years)</small><div className="text-dark">{!_.isEmpty(`${projectData.concession_period}`) ? projectData.concession_period : '-'}</div>
                            </Col>
                            <Col sm={3}>
                                <small className={`font-weight-light ${highLightValues.includes("const_period") && "data-title"}`} data-title={highLightValues.includes("const_period")?heightData.filter((e)=>e.id==="const_period").map((e)=>{
                                    return `Previous Value : \n${e.old_value}`
                            }):'Construction Period (Months)'} >Construction Period (Months)</small><div className="text-dark">{!_.isEmpty(projectData.const_period) ? projectData.const_period : '-'}</div>
                            </Col>
                            <Col sm={3}>
                                <small className={`font-weight-light ${highLightValues.includes("basis_award") && "data-title"}`} data-title={highLightValues.includes("basis_award")?heightData.filter((e)=>e.id==="basis_award").map((e)=>{
                                    return `Previous Value : \n${e.old_value}`
                            }):'Basis Of Award'} >Basis Of Award</small><div className="text-dark">{!_.isEmpty(`${projectData.basis_award}`) ? projectData.basis_award : '-'}</div>
                            </Col>
                        </Row>
                        <div className='d-flex justify-content-between mt-3'>
                            <div className='w-100'>
                                <div className="d-flex w-100 pr-4 timeline">
                                    <div className='card1 label-card'>
                                        <small className={`font-weight-light ${highLightValues.includes("date_rfp") && "data-title"}`} data-title={highLightValues.includes("date_rfp")?heightData.filter((e)=>e.id==="date_rfp").map((e)=>{
                                    return `Previous Value : \n ${moment(Number(e.old_value)).format('MMM-YYYY') !=="Invalid date" ?
                                    moment(Number(e.old_value)).format('MMM-YYYY'): "-"}`
                                }):'RFP'} >RFP</small>
                                        <div style={{position: 'relative'}}>
                                            <div className="text-dark">{projectData.date_rfp ? moment.unix(projectData.date_rfp).format('MMM-YYYY') : '-'}</div>
                                        </div>
                                    </div>
                                    <div className='card1 label-card'>
                                        <small className={`font-weight-light ${highLightValues.includes("date_rfp") && "data-title"}`} data-title={highLightValues.includes("date_rfp")?heightData.filter((e)=>e.id==="date_rfp").map((e)=>{
                                    return `Previous Value : \n ${moment(Number(e.old_value)).format('MMM-YYYY') !=="Invalid date" ?
                                    moment(Number(e.old_value)).format('MMM-YYYY'): "-"}`
                                }):'RFP'} >RFQ</small>
                                        <div style={{position: 'relative'}}>
                                            <div className="text-dark">{projectData.date_rfq ? moment.unix(projectData.date_rfq).format('MMM-YYYY') : '-'}</div>
                                        </div>
                                    </div>
                                    <div className='card1 label-card'>
                                    <small className={`font-weight-light ${highLightValues.includes("award_date") && "data-title"}`}  data-title={highLightValues.includes("award_date")?heightData.filter((e)=>e.id==="award_date").map((e)=>{
                                    return `Previous Value : \n${moment(Number(e.old_value)).format('MMM-YYYY')!=="Invalid date"?
                                    moment(Number(e.old_value)).format('MMM-YYYY'):  "-"}`
                                }):'Letter Of Award'} >Letter Of Award</small>
                                        <div style={{position: 'relative'}}>
                                            <div className="text-dark">{projectData.award_date ? moment.unix(projectData.award_date).format('MMM-YYYY') : '-'}</div>
                                        </div>
                                    </div>
                                    <div className='card2 label-card'>
                                    <small className={`font-weight-light ${highLightValues.includes("agreement_signed_on") && "data-title"}`} data-title={highLightValues.includes("agreement_signed_on")?heightData.filter((e)=>e.id==="agreement_signed_on").map((e)=>{
                                    return `Previous Value : \n${moment(Number(e.old_value)).format('MMM-YYYY')!=="Invalid date"?
                                    moment(Number(e.old_value)).format('MMM-YYYY'):  "-"}`
                                }):'Con. Agreement Signed'} >Con. Agreement Signed</small>
                                        <div style={{position: 'relative'}}>
                                            <div className="text-dark">{projectData.agreement_signed_on ? moment.unix(projectData.agreement_signed_on).format('MMM-YYYY') : '-'}</div>
                                        </div>
                                    </div>
                                    <div className='card2 label-card'>
                                    <small className={`font-weight-light ${highLightValues.includes("financial_closure") && "data-title"}`}
                                        data-title={highLightValues.includes("financial_closure")?heightData.filter((e)=>e.id==="financial_closure").map((e)=>{
                                            return `Previous Value : \n${moment(Number(e.old_value)).format('MMM-YYYY')!=="Invalid date"?
                                            moment(Number(e.old_value)).format('MMM-YYYY'):  "-"}`
                                    }):'Financial Closure'}
                                    >Financial Closure</small>                                    
                                        <div style={{position: 'relative'}}>
                                        <div className="text-dark">{projectData.financial_closure ? moment.unix(projectData.financial_closure).format('MMM-YYYY') : '-'}</div>
                                        </div>
                                    </div>
                                    <div className='card2 label-card'>
                                    <small className={`font-weight-light ${highLightValues.includes("cons_start_date") && "data-title"}`} data-title={highLightValues.includes("cons_start_date")?heightData.filter((e)=>e.id==="cons_start_date").map((e)=>{
                                    return `Previous Value : \n${moment(Number(e.old_value)).format('MMM-YYYY')!=="Invalid date"?
                                    moment(Number(e.old_value)).format('MMM-YYYY'): "-"}`
                                }):'Cons. Commenced'}>Cons. Commenced</small>
                                        <div style={{position: 'relative'}}>
                                        <div className="text-dark">{projectData.cons_start_date ? moment.unix(projectData.cons_start_date).format('MMM-YYYY') : '-'}</div>
                                        </div>
                                    </div>
                                    <div className='card2 label-card'>
                                        <small  className={`font-weight-light ${highLightValues.includes("cons_completion") && "data-title"}`} data-title={highLightValues.includes("cons_completion")?heightData.filter((e)=>e.id==="cons_completion").map((e)=>{
                                        return `Previous Value : \n${moment(Number(e.old_value)).format('MMM-YYYY')!=="Invalid date"?
                                        moment(Number(e.old_value)).format('MMM-YYYY'):  "-"}`
                                    }):'Sch. Completion'} >Sch. Completion</small>
                                        <div style={{position: 'relative'}}>
                                            <div className="text-dark">{projectData.cons_completion ? moment.unix(projectData.cons_completion).format('MMM-YYYY') : '-'}</div>
                                        </div>
                                    </div>
                                    <div className='card3 label-card'>
                                    <small className={`font-weight-light ${highLightValues.includes("ant_comp_date") && "data-title"}`} data-title={highLightValues.includes("ant_comp_date")?heightData.filter((e)=>e.id==="ant_comp_date").map((e)=>{
                                    return `Previous Value : \n${moment(Number(e.old_value)).format('MMM-YYYY')!=="Invalid date"?
                                    moment(Number(e.old_value)).format('MMM-YYYY'):  "-"}`
                                }):'Anticipated Completion'} >Anticipated Completion</small>
                                        <div style={{position: 'relative'}}>
                                        <div className="text-dark">{projectData.ant_comp_date ? moment.unix(projectData.ant_comp_date).format('MMM-YYYY') : '-'}</div>
                                        </div>
                                    </div>
                                    <div className='card3 label-card'>
                                    <small className={`font-weight-light ${highLightValues.includes("actual_comp_date") && "data-title"}`} data-title={highLightValues.includes("actual_comp_date")?heightData.filter((e)=>e.id==="actual_comp_date").map((e)=>{
                                    return `Previous Value : \n${moment(Number(e.old_value)).format('MMM-YYYY')!=="Invalid date"?
                                    moment(Number(e.old_value)).format('MMM-YYYY'):  "-"}`
                                    }):'Actual Completion'}>Actual Completion</small>
                                        <div style={{position: 'relative'}}>
                                        <div className="text-dark">{projectData.actual_comp_date ? moment.unix(projectData.actual_comp_date).format('MMM-YYYY') : '-'}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center pl-4 pr-2 border-left algn-items-center d-flex">
                        <Card className={`${_.lte(+projectData.time_overrun, 0) ? 'green' : 'red' } time-card m-auto`}>
                            <small className="font-weight-light" style={!_.eq(projectData.time_overrun, '') ? _.eq(projectData.time_overrun, 'NA') ? {textAlign: 'center'} : {textAlign: 'left'} : {textAlign: 'center'}}>{projectData.time_overrun_label}</small>
                            {!_.eq(projectData.time_overrun, '') ? _.eq(projectData.time_overrun, 'NA') ? (<span style={{marginTop: '10px'}}>NA</span>) : (
                                <>
                                <div className='time-arrow'>
                                    {_.lte(+projectData.time_overrun, 0) ? (<FontAwesomeIcon style={{ color: 'green' }} icon={['fas', 'arrow-down']} />) : (<FontAwesomeIcon style={{ color: 'red' }} icon={['fas', 'arrow-up']} />)}
                                </div>
                                <Fragment>
                                    <div className={`${_.lte(+projectData.time_overrun, 0) ? 'green' : 'red' } text-dark timeover`}>{projectData.time_overrun} <small className='font-weight-light' style={_.lte(+projectData.time_overrun, 0) ? {color: 'green'} : {color: 'red'}}> Months</small></div>
                                </Fragment>
                                </>
                            ) : (<span style={{marginTop: '10px'}}>-</span>)}
                        </Card>
                        </div>
                        </div>
                </CardBody>
            </Card>
            </>
        );
    }

    static PCODInfoCard(props) {
        const { projectData,highLightValues,heightData } = props;
        const pcoddate = projectData?.pcoddata ? JSON.parse(projectData?.pcoddata) : [];
        return (
            <Row>
                <Col className="pl-2 col-md-12">
                    <Card className="card-box card-list-box mb-3 border-0 project-view-details-card project-list-card pcod">
                        <CardHeader>
                        <div className='d-flex justify-content-between'>
                            <span className="text-dark title">PCOD</span>
                        </div>
                        </CardHeader>
                        <CardBody className="pt-0 pb-2">
                           
                        {pcoddate?.length > 0 ? (
                            <>
                             <Row className="head">
                                <Col sm={5}>
                                    <div className='pcod-date label-card head'>
                                        <div class=" label-card"><small class="font-weight-light">Month - Year</small></div>
                                        <div class=" label-card"><small class="font-weight-light">Km</small></div>
                                    </div>
                                </Col>
                                <Col className="pl-0 pr-2">
                                <div class=" label-card mb-0 pl-0 pr-2"><small class="font-weight-light">Remark</small></div>
                                </Col>
                            </Row>
                            {pcoddate.map((e, index) => (
                            <Row key={index} className="align-items-center">
                                <Col sm={5}>
                                    <div class="pcod-date label-card mb-0">
                                        <div class="text-dark">{moment.unix(e.pcod_date).format('MMM-YYYY')}</div>
                                        <div className='divider'></div>
                                        <div class="text-dark">{e.pcod_km ? e.pcod_km +' Km' : ''}</div>
                                    </div>
                                </Col>
                                <Col className="pl-0 pr-2">
                                <div class="label-card mb-0 remark"><div class="">{e.pcod_remarks ? e.pcod_remarks : '-'}</div></div>
                                </Col>
                            </Row>
                            ))}
                            </>
                            ) : <div className="text-dark text-capitalize">No Data available</div> }
                            
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        );
    }

    static BankInfoCard(props) {
        const { projectData,highLightValues,heightData } = props;
        return (
            <Row>
                <Col className="col-md-12">
                    <Card className="card-box card-list-box mb-3 border-0 project-view-details-card project-list-card">
                        <CardHeader>
                        <div className='d-flex justify-content-between'>
                            <span data-title={highLightValues.includes("m_bank_id")?heightData.filter((e)=>e.id==="m_bank_id").map((e)=>{
                                return `Previous Value : \n${e.old_value}`
                            }):"Bank"} className={`mb-0 text-black py-1 px-1 project-name text-dark title ${highLightValues.includes("m_bank_id") && "data-title"}`} >Banks Info</span>
                        </div>
                        </CardHeader>
                        <CardBody className="pt-0">
                            <div className='d-flex gap-2 flex-wrap'>
                                {
                                    _.size(projectData.bank_name) ? _.map(_.values(projectData.bank_name), (names, indx) => (
                                        <div key={names + '_~' + indx} className="card-border px-2 py-1">
                                            {
                                                names !== "" ? <div className="text-dark">{names}</div> : '-'
                                            }
                                        </div>
                                    )) : (<div>
                                        <div className="text-dark text-capitalize">No data available</div>
                                    </div>)
                                }
                            </div>
                            
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        );
    }

    static MemberInfoCard(props) {
        const { projectData,highLightValues,heightData } = props;


        return (
            <Row>
                <Col md={12} className="pl-2">
                    <Card className="card-box card-list-box mb-3 border-0 project-view-details-card project-list-card">
                        <CardHeader>
                    <div className='d-flex justify-content-between'>
                    <span  data-title={highLightValues.includes("ind_engineer")?heightData.filter((e)=>e.id==="ind_engineer").map((e)=>{
                                return `Previous Value : \n${e.old_value}`
                          }):'Independent Engineer'}className={`mb-0 text-black py-1 px-1 project-name text-dark title ${highLightValues.includes("ind_engineer") && "data-title"}`}>Independent Engineer</span>
                    </div>
                    </CardHeader>
                        <CardBody className="pt-0">
                            <Row className="mx-0">
                                {
                                    _.size(projectData.ind_engineer) ? _.map(_.values(projectData.ind_engineer), (names, indx) => (

                                        <Col md={12}  key={names + '_~' + indx} className={_.size(projectData.ind_engineer) > 1 && indx !== (_.size(projectData.ind_engineer) - 1) ? "border-bottom pb-2 px-0" : "px-0"}>
                                            {
                                                names !== "" ? <div className="text-dark">{indx + 1}. {names}</div> : '-'
                                            }
                                        </Col>
                                    )) : (<Col md={12}>
                                        <div className="text-dark text-capitalize">No Data available</div>
                                    </Col>)
                                }
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={12} className="pl-2">
                    <Card className="card-box card-list-box mb-3 border-0 project-view-details-card project-list-card">
                        <CardHeader>
                        <div className='d-flex justify-content-between'>
                        <span data-title={highLightValues.includes("other_members")?heightData.filter((e)=>e.id==="other_members").map((e)=>{
                                return `Previous Value : \n${e.old_value}`
                          }):"Other Members"}className={`mb-0 text-black py-1 px-1 project-name text-dark title ${highLightValues.includes("other_members") && "data-title"}`}>Other Members</span>
                        </div>
                        </CardHeader>
                        <CardBody className="pt-0">
                            <div className='d-flex gap-2  flex-wrap'>
                                {
                                    _.size(projectData.other_members) ? _.map(_.values(projectData.other_members), (names, indx) => (
                                        <>
                                        {names !== "" && 
                                        <div key={names + '_~' + indx}>
                                           <div  className="card-border px-2 py-1"><div className="text-dark">{names}</div></div>
                                        </div>}
                                        {names === "" && projectData.other_members.length === 1 && 
                                        <div className="text-dark text-capitalize">No data available</div>}
                                        </>
                                    )) : (<div>
                                        <div className="text-dark text-capitalize">No data available</div>
                                    </div>)
                                }
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        );
    }

    static EditInfoCard(props) {
        const { projectDatalog } = props;

        const dateFormate = ["date_rfp",
            "date_rfq",
            "award_date",
            "agreement_signed_on",
            "cons_start_date",
            "actual_comp_date",
            "cons_completion",
            "ant_comp_date",
            "financial_closure"]

        const columnObj = [
            { Header: '#', accessor: 'rec_count', width: "3%", className: 'text-center align-top', Cell: ({ row }) => <div> {row.index + 1}</div> },
            { Header: 'UPDATED BY', accessor: 'user_name', width: '0%', className: 'text-left d-none' },

            { Header: 'UPDATED ON', accessor: 'formatted_date', width: '0%', className: 'text-left- d-none' },
            // {
            {
                Header: 'DETAILS', accessor: 'log_detail', width: '97%', className: 'text-center', Cell: ({ row }) => {

                    var details = JSON.parse(row.values.log_detail)


                    return (
                        <Card>
                            <CardBody>
                                <Row className="justify-content-left" >
                                    <Col md={6} className="mb-0 py-1 px-1 project-name" style={{ fontSize: '0.83rem', fontWeight: "600", color: "#3b3e66", textAlign: "left", marginLeft: "16px" }}>
                                        {details[0]?.field_label === 'New Value' ? 'Added BY' : 'Updated By'}: <span style={{ fontWeight: "500", color: "#000" }}>{row.original.user_name}</span> </Col>
                                    <Col md={6} className="mb-0  py-1 px-1 project-name" style={{ fontSize: '0.83rem', fontWeight: "600", color: "#3b3e66", textAlign: "left", marginLeft: "-16px" }}>
                                        {details[0]?.field_label === 'New Value' ? 'Added On' : 'Updated On'} : <span style={{ fontWeight: "500", color: "#000" }}>{row.original.formatted_date}</span></Col>

                                </Row>
                                <table className='w-100' >
                                    <thead>
                                        <tr>
                                            <th style={{ width: '200px', maxWidth: "200px", color: "#3b3e66", textAlign: "left" }} >Field Name</th>
                                            <th style={{ width: '200px', maxWidth: "200px", color: "#3b3e66", textAlign: "left" }} >Old Value</th>
                                            <th style={{ width: '200px', maxWidth: "200px", color: "#3b3e66", textAlign: "left" }} >Updated Value</th>
                                            <th style={{ width: '300px', maxWidth: "300px", color: "#3b3e66", textAlign: "left" }} >Source</th>
                                            <th style={{ width: '300px', maxWidth: "300px", color: "#3b3e66", textAlign: "left" }} >Remarks</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {details?.map((item) => {

                                            return (
                                                <tr>
                                                    <td style={{ width: '200px', maxWidth: "200px", textAlign: "left" }} className='text_left  '>{item?.field_label}</td>
                                                    <td style={{ width: '200px', maxWidth: "200px", textAlign: "left" }} className='text-left '>
                                                        {typeof (item?.old_value) !== "string" ? <>
                                                            {item?.old_value?.length > 0 ? <> {item?.old_value?.map(val => {
                                                                return (

                                                                    <p className="rounded-2  p-1 mb-0" style={{ wordBreak: "break-all" }}>{val}</p>

                                                                )
                                                            })} </> : "-"} </>
                                                            : <>
                                                                <p className='mb-0' style={{ wordBreak: "break-all" }}>
                                                                    {dateFormate.includes(item.field_name)&& item?.old_value!=="-" && item.old_value!==""?
                                                                        moment(Number(item?.old_value)).format("MMM-YYYY") === 'Invalid date' ?
                                                                        moment(item?.old_value).format("MMM-YYYY")
                                                                      : moment(Number(item?.old_value)).format("MMM-YYYY")
                                                                      : item?.old_value!==""?item?.old_value:'-'}</p>
                                                            </>} </td>

                                                    <td style={{ width: '200px', maxWidth: "200px", textAlign: "left", wordBreak: "break-all" }} className='text-left' >
                                                        {typeof (item?.new_value) !== "string" ? <>
                                                            {item?.new_value?.length > 0 ? <> {item?.new_value?.map(val => {
                                                                return (
                                                                    <p className="rounded-2  p-1 mb-0" style={{ wordBreak: "break-all" }}>{val}</p>
                                                                )
                                                            })} </> : "-"} </>
                                                            : <>
                                                                <p className=' mb-0' style={{ wordBreak: "break-all" }} >{dateFormate.includes(item.field_name) && item?.new_value!=="-" && item.new_value!==""? moment(Number(item?.new_value)).format("MMM-YYYY") === 'Invalid date' ?
                                                                    moment(item?.new_value).format("MMM-YYYY") : moment(Number(item?.new_value)).format("MMM-YYYY") : item?.new_value!==""?item?.new_value:'-'}</p>
                                                            </>} </td>
                                                    <td style={{ width: '300px', maxWidth: "300px", textAlign: "left" }} className='text_left '><div className='d-flex align-items-center' style={{ wordBreak: "break-all" }}> {item?.source_name}</div></td>
                                                    <td style={{ width: '300px', maxWidth: "300px", textAlign: "left" }} className='text_left '><div className='d-flex align-items-center' style={{ wordBreak: "break-all" }}> {item?.edit_remark}</div></td>

                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </CardBody>
                        </Card>
                    )

                }
            },
        ];

        const columns = useMemo(() => columnObj, []);
        let res_data = projectDatalog ? projectDatalog : [];

        let data = React.useMemo(() => res_data, [res_data]);


        const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, nexPage, previousPage, gotoPage, setPageSize, state, setGlobalFilter } = useTable({ columns, data }, useGlobalFilter, usePagination,);


        const { pageIndex, pageSize, globalFilter } = state

        const onPageChange = (current, pageSize) => {
            gotoPage(current - 1)

        }

        const changePage = (PageChange, size) => {
            setPageSize(size)
        }
        useEffect(() => {
            changePage(1, 10)
        }, [])


        return (
            <Card className="card-box card-list-box mb-3 border-0 project-view-details-card project-list-card">
                <CardHeader>
                    <div className='d-flex justify-content-between'>
                        <span className="text-dark title">Histroy</span>
                    </div>
                    </CardHeader>
                <CardBody >
                        {
                            data?.length>10&&

                        <Row className='justify-content-end '>
                            <InputGroup style={{ maxWidth: '250px', marginBottom: '5px' }} className="filter-input-group">
                                <Input placeholder="Search" className="form-control form-control-sm rounded-0" value={globalFilter || ""} onChange={(e) => setGlobalFilter(e.target.value)} style={{ width: '275px' }} />
                                <InputGroupAddon addonType="append"><Button size="sm" style={{ backgroundColor: '#dadada' }} className="rounded-0 border-0 transform-none box-shadow-none"><FontAwesomeIcon icon={['fas', 'search']} size="sm" /></Button></InputGroupAddon>
                            </InputGroup>
                            {/* <Col>
                        <Input value={globalFilter||""}  onChange={(e)=>setGlobalFilter(e.target.value)}/>
                        </Col> */}
                        </Row>
                           }
                        <div style={{height:"700px",overflowY:"auto",overflowX:"hidden"}}>
                        <table {...getTableProps({ className: "table table-bordered table-custom mb-0" })}>
                            <thead>
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps(
                                    )}>
                                        {headerGroup.headers.map(column => (
                                            <th {...column.getHeaderProps(column.id === 'user_name' || column.id === 'formatted_date' ? { style: { display: 'none' } } : { style: { zIndex: 10, top: -1, textAlign: "center" } })}>{column.render('Header')}</th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {_.size(page) === 0 ? (
                                    <tr>
                                        <td colSpan={_.size(columns)} className="text-center">No Data Found</td>
                                    </tr>
                                ) : page.map((row, i) => {
                                    prepareRow(row)
                                    return (
                                        <>
                                            <tr {...row.getRowProps()}>
                                                {row.cells.map(cell => {
                                                    return <td {...cell.getCellProps({ className: cell.column.className ? cell.column.className : '' })}>{cell.render('Cell')}</td>
                                                })}
                                            </tr>
                                        </>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    {data.length > 10 &&
                        <Pagination
                            selectComponentClass={Select}
                            pageSizeOptions={['10', '25', '50', '75', '100']}
                            showTotal={(total, [from, to]) => { let _from = +from < 0 ? 1 : from; let _to = to > -1 ? to : total; return (<>{_from} - {_to} Of {total}</>) }}
                            defaultPageSize={10}
                            pageSize={pageSize}

                            defaultCurrent={1}
                            // current={page1.current}
                            total={data.length}
                            onChange={onPageChange}
                            onShowSizeChange={changePage}
                            style={{ borderRadius: '4px', fontSize: '12px' }}
                            wrapperClassName={cx('p-3 my-2 pagination-container')}
                        />}
                </CardBody>
            </Card>
        )
    }

    static Upload({projectId,state,ROAD_PROJECT_LIST}){
         const {getData}=state
        const [postPerPage,setPostPerPage]=useState(5)
        const[currentPage,setCurrentPage]=useState(1)

          const changeOn = (current, pageSize) => {
            // gotoPage(current - 1)
          setCurrentPage(current)
          }

          const changePage = (PageChange, size) => {
            setPostPerPage(size)
          }
          useEffect(() => {
            changePage(1, 5)
          }, [])
        const indexOfLastPost = currentPage * postPerPage;
      const indexOfFirstPost = indexOfLastPost - postPerPage;
      const currentPosts = getData.slice(indexOfFirstPost, indexOfLastPost);

      const getDocType = (url) => {
        const extension = url.split('.').pop().toLowerCase();
        const typeMap = {
          pdf: 'pdf',
          jpg: 'image',
          jpeg: 'image',
          png: 'image',
          doc: 'doc',
          docx: 'doc',
          ppt: 'ppt',
          pptx: 'ppt',
          xls: 'excel',
          xlsx: 'excel',
          txt: 'text',
        };
      
        return typeMap[extension] || 'unknown';
      };

      return(
        <>
            <Card className='card-box card-list-box mb-3 border-0 project-list-card'>
            <CardHeader>
                <div className='d-flex justify-content-between'>
                    <span className="text-dark title">Document Details</span>
                </div>
                </CardHeader>
                <CardBody className="pt-0">
                    {getData.length > 0 ? 
                    <div className="document-list">
                        {getData.map((doc, indx) => {
                            const type = getDocType(doc.file_path);
                            return (
                            <div key={doc + '_~' + indx} className='document'>
                                <div className="document-card">
                                    <div className="document-thumbnail">
                                        <img
                                        src={`/assets/images/icons/${type}.png`}
                                        alt={doc.file_name}
                                        />
                                    </div>
                                    <div className='overlay1'></div>
                                    <div className="document-info">
                                        <p className="document-name">{doc.file_name}</p>
                                        <div className="document-actions">
                                        <Button size="sm" color="link" href={/^(http|https):\/\/[^ "]+$/.test(doc.file_path)?doc.file_path:atob(doc.file_path)}  target='_blank'className=" btn btn-sm border-1 btn-link" > 
                                            <span className='btn-wrapper--icon icon'><i className="fa-solid fa-eye m-0"></i></span></Button>
                                        </div>
                                    </div>
                                </div>
                                <div className='doc-type text-dark'>{doc?.doc_name ? doc.doc_name : ''}</div>
                            </div>
                        )})}
                    </div>
                    : <div className="text-dark text-capitalize">No data available</div>}
                </CardBody>
            </Card>
        </>
      )

    }

    static RoadToll = (props) => {

        const { tollMarker, facilities, projectData, expandToggle, rSelected, refMap} = props;
        const { district_name } = projectData
        return (
            <>
                <Card className='card-box card-list-box  mb-3 border-0 project-list-card tollinfo'>
                    <CardHeader>
                        <div className='d-flex justify-content-between align-items-center w-100'>
                            <span className="text-dark title">Toll Plaza</span>
                            {this.projectId>0&& this.state.mapLoading&& (this.state.stretch.length>0 || this.state.tollMarker.length>0)&& !window.location.pathname.includes("/rdqry") && tollMarker.length > 0 && <div>
                                <label className='mr-2 font-weight-normal'>Show in Map </label>
                                <ButtonGroup>
                                    <Button size="sm"
                                        color="info"
                                        outline
                                        onClick={() => {expandToggle(1)}}
                                        active={rSelected === 1}
                                    >
                                        Yes
                                    </Button>
                                    <Button size="sm"
                                        color="info"
                                        outline
                                        onClick={() => expandToggle(2)}
                                        active={rSelected === 2}
                                    >
                                        No
                                    </Button>
                                </ButtonGroup>
                            </div>}
                        </div>
                    </CardHeader>

                    <CardBody className={`${tollMarker.length > 0 ? 'pb-2 pt-0' : 'pt-0'}`}>
                        {tollMarker.length > 0 ? <>
                            <div className='head'>
                                <Row className="d-flex">
                                    <Col className="sno">
                                        <div className=" label-card">
                                            <small className="font-weight-light">#</small>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className=" label-card">
                                            <small className="font-weight-light">Name</small>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className=" label-card">
                                            <small className="font-weight-light">Stretch (Km)</small>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className=" label-card">
                                            <small className="font-weight-light">District</small>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className=" label-card">
                                            <small className="font-weight-light">Location</small>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className=" label-card">
                                            <small className="font-weight-light">Facilities</small>
                                        </div>
                                    </Col>
                                    <Col className="action">
                                        <div className=" label-card">
                                            <small className="font-weight-light">View</small>
                                        </div>
                                    </Col>
                                    {/* <Col className="fin-col">
                                        <small className="font-weight-light"></small>
                                    </Col> */}
                                </Row>
                            </div>
                            {
                                tollMarker?.map((e, i) => {
                                    const data = e.m_toll_facitilites_id
                                    const [toggel, setToggel] = useState(false)
                                    return (
                                        <div className='content'>
                                        <Row className="d-flex align-items-center">
                                            <Col className="sno">
                                                <div className=" label-card">
                                                    <div>
                                                        <div className="">{i + 1}</div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className=" label-card">
                                                    <div>
                                                        <div className="">{e.toll_name}</div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className=" label-card">
                                                    <div>
                                                        <div className="">{e.toll_length}</div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className=" label-card">
                                                    <div>
                                                        <div className="">{e.district_name ? e.district_name : '-'}</div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className=" label-card">
                                                    <div>
                                                        <div className="">{e.location_name ? e.location_name : '-'}</div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className=" label-card">
                                                    <div>
                                                        <div className="">{facilities.filter((f) => data.includes(Number(f.m_toll_facitilites_id))).map((s) => s.facility_name).join(',')}</div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col className="action">
                                                <div className="">
                                                    <Button type="button" color="link" title="View Details" className=" btn btn-sm btn-link" onClick={() => setToggel(!toggel)}>
                                                            <span className='btn-wrapper--icon icon'><i className="fa-solid fa-indian-rupee-sign m-0"></i></span></Button>
                                                </div>
                                            </Col>
                                            {/* <Col className="fin-col">
                                                <div>
                                                    <div className=""></div>
                                                </div>
                                            </Col> */}
                                        </Row>
                                        <Row className={`expand_table ${!toggel ? '' : 'active'}`}>
                                            <Col sm={12}>
                                                <table className='table table-bordered qry-res-table table-custom mt-3 mb-0'>
                                                    <thead>
                                                        <tr>
                                                            <th >VEHICLE Type</th>
                                                            <th >Single Trip  (Rs)</th>
                                                            <th >Return Trip  (Rs)</th>
                                                            <th >Daily (Rs)</th>
                                                            <th >Monthly  (Rs)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {

                                                            <tr>
                                                                <td>2 Wheeler</td>
                                                                <td>
                                                                    {e.single_pass.two_wheeler ?? '-'}
                                                                </td>
                                                                <td>
                                                                    {e.return_pass.two_wheeler ?? '-'}
                                                                </td>
                                                                <td>
                                                                    {e.day_pass.two_wheeler ?? '-'}
                                                                </td>
                                                                <td>
                                                                    {e.month_pass.two_wheeler ?? '-'}
                                                                </td>

                                                            </tr>
                                                        }
                                                        {

                                                            <tr>
                                                                <td>3 Wheeler</td>
                                                                <td>
                                                                    {e.single_pass.three_wheeler ?? '-'}
                                                                </td>
                                                                <td>
                                                                    {e.return_pass.three_wheeler ?? '-'}
                                                                </td>
                                                                <td>
                                                                    {e.day_pass.three_wheeler ?? '-'}
                                                                </td>
                                                                <td>
                                                                    {e.month_pass.three_wheeler ?? '-'}
                                                                </td>

                                                            </tr>
                                                        }
                                                        {

                                                            <tr>
                                                                <td>Car/Jeep</td>
                                                                <td>
                                                                    {e.single_pass.car_jeep ?? '-'}
                                                                </td>
                                                                <td>
                                                                    {e.return_pass.car_jeep ?? '-'}
                                                                </td>
                                                                <td>
                                                                    {e.day_pass.car_jeep ?? '-'}
                                                                </td>
                                                                <td>
                                                                    {e.month_pass.car_jeep ?? '-'}
                                                                </td>

                                                            </tr>
                                                        }
                                                        {

                                                            <tr>
                                                                <td>Bus</td>
                                                                <td>
                                                                    {e.single_pass.bus ?? '-'}
                                                                </td>
                                                                <td>
                                                                    {e.return_pass.bus ?? '-'}
                                                                </td>
                                                                <td>
                                                                    {e.day_pass.bus ?? '-'}
                                                                </td>
                                                                <td>
                                                                    {e.month_pass.bus ?? '-'}
                                                                </td>

                                                            </tr>
                                                        }
                                                        {

                                                            <tr>
                                                                <td>Truck</td>
                                                                <td>
                                                                    {e.single_pass.truck_wheeler ?? '-'}
                                                                </td>
                                                                <td>
                                                                    {e.return_pass.truck_wheeler ?? '-'}
                                                                </td>
                                                                <td>
                                                                    {e.day_pass.truck_wheeler ?? '-'}
                                                                </td>
                                                                <td>
                                                                    {e.month_pass.truck_wheeler ?? '-'}
                                                                </td>

                                                            </tr>
                                                        }
                                                        {

                                                            <tr>
                                                                <td>MAV</td>
                                                                <td>
                                                                    {e.single_pass.mav_wheeler ?? '-'}
                                                                </td>
                                                                <td>
                                                                    {e.return_pass.mav_wheeler ?? '-'}
                                                                </td>
                                                                <td>
                                                                    {e.day_pass.mav_wheeler ?? '-'}
                                                                </td>
                                                                <td>
                                                                    {e.month_pass.mav_wheeler ?? '-'}
                                                                </td>

                                                            </tr>
                                                        }



                                                    </tbody>
                                                </table>
                                            </Col>
                                        </Row>
                                        </div>
                                    )
                                })
                            }
                            {/* <table className="custom-table">
                                <thead className="thead-light">
                                    <tr>
                                        <th >#</th>
                                        <th scope="col">Name</th>
                                        <th scope="col" style={{ width: '30px' }}>Stretch(km)</th>
                                        <th scope="col">District</th>
                                        <th scope="col">Location</th>
                                        <th scope="col">Facilities</th>
                                        <th scope="col" className='text-center'>Action</th>
                                    </tr>
                                </thead>
                                {
                                    tollMarker?.map((e, i) => {

                                        const data = e.m_toll_facitilites_id

                                        const [toggel, setToggel] = useState(false)
                                        return (
                                            <tbody>
                                                <tr>
                                                    <td scope="row" className='text-center'>{i + 1}</td>
                                                    <td>  {e.toll_name}</td>
                                                    <td className='text-center ' >{e.toll_length}</td>
                                                    <td>{district_name}</td>
                                                    <td>{e.toll_location}</td>
                                                    <td>{facilities.filter((f) => data.includes(Number(f.m_toll_facitilites_id))).map((s) => s.facility_name).join(',')}</td>
                                                    <td className='text-center'>
                                                        <Button type="button" color="link" title="Fare Fare" className=" btn btn-sm border-1 btn-link" onClick={() => setToggel(!toggel)}>
                                                            <span className='btn-wrapper--icon icon'><i className="fa-solid fa-indian-rupee-sign m-0"></i></span></Button>
                                                    </td>
                                                </tr>
                                                <tr className={`expand_table ${!toggel ? '' : 'active'}`}>
                                                    <td colSpan={8}>
                                                        <table className='table table-bordered qry-res-table table-hover table-custom mt-2 '>
                                                            <thead>
                                                                <tr>
                                                                    <th >VEHICLE Type</th>
                                                                    <th >Single Trip  (Rs)</th>
                                                                    <th >Return Trip  (Rs)</th>
                                                                    <th >Daily (Rs)</th>
                                                                    <th >Monthly  (Rs)</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {

                                                                    <tr>
                                                                        <td>2 Wheeler</td>
                                                                        <td>
                                                                            {e.single_pass.two_wheeler ?? '-'}
                                                                        </td>
                                                                        <td>
                                                                            {e.return_pass.two_wheeler ?? '-'}
                                                                        </td>
                                                                        <td>
                                                                            {e.day_pass.two_wheeler ?? '-'}
                                                                        </td>
                                                                        <td>
                                                                            {e.month_pass.two_wheeler ?? '-'}
                                                                        </td>

                                                                    </tr>
                                                                }
                                                                {

                                                                    <tr>
                                                                        <td>3 Wheeler</td>
                                                                        <td>
                                                                            {e.single_pass.three_wheeler ?? '-'}
                                                                        </td>
                                                                        <td>
                                                                            {e.return_pass.three_wheeler ?? '-'}
                                                                        </td>
                                                                        <td>
                                                                            {e.day_pass.three_wheeler ?? '-'}
                                                                        </td>
                                                                        <td>
                                                                            {e.month_pass.three_wheeler ?? '-'}
                                                                        </td>

                                                                    </tr>
                                                                }
                                                                {

                                                                    <tr>
                                                                        <td>Car/Jeep</td>
                                                                        <td>
                                                                            {e.single_pass.car_jeep ?? '-'}
                                                                        </td>
                                                                        <td>
                                                                            {e.return_pass.car_jeep ?? '-'}
                                                                        </td>
                                                                        <td>
                                                                            {e.day_pass.car_jeep ?? '-'}
                                                                        </td>
                                                                        <td>
                                                                            {e.month_pass.car_jeep ?? '-'}
                                                                        </td>

                                                                    </tr>
                                                                }
                                                                {

                                                                    <tr>
                                                                        <td>Bus</td>
                                                                        <td>
                                                                            {e.single_pass.bus ?? '-'}
                                                                        </td>
                                                                        <td>
                                                                            {e.return_pass.bus ?? '-'}
                                                                        </td>
                                                                        <td>
                                                                            {e.day_pass.bus ?? '-'}
                                                                        </td>
                                                                        <td>
                                                                            {e.month_pass.bus ?? '-'}
                                                                        </td>

                                                                    </tr>
                                                                }
                                                                {

                                                                    <tr>
                                                                        <td>Truck</td>
                                                                        <td>
                                                                            {e.single_pass.truck_wheeler ?? '-'}
                                                                        </td>
                                                                        <td>
                                                                            {e.return_pass.truck_wheeler ?? '-'}
                                                                        </td>
                                                                        <td>
                                                                            {e.day_pass.truck_wheeler ?? '-'}
                                                                        </td>
                                                                        <td>
                                                                            {e.month_pass.truck_wheeler ?? '-'}
                                                                        </td>

                                                                    </tr>
                                                                }
                                                                {

                                                                    <tr>
                                                                        <td>MAV</td>
                                                                        <td>
                                                                            {e.single_pass.mav_wheeler ?? '-'}
                                                                        </td>
                                                                        <td>
                                                                            {e.return_pass.mav_wheeler ?? '-'}
                                                                        </td>
                                                                        <td>
                                                                            {e.day_pass.mav_wheeler ?? '-'}
                                                                        </td>
                                                                        <td>
                                                                            {e.month_pass.mav_wheeler ?? '-'}
                                                                        </td>

                                                                    </tr>
                                                                }



                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        )
                                    })
                                }
                            </table> */}
                        </> : <div className="text-dark text-capitalize">No data available</div>}
                    </CardBody>
                </Card>
            </>
        )
    }

    openHistory=()=>{
        this.setState({loadButton:true})
        if (this.projectId > 0) {
            this.props.getRoadProjectDetailsLog(this.projectId).then((res) => {
                setTimeout(()=>{
                    if(  this.history.current){
                        this.history.current.scrollIntoView({behavior:'smooth', block: "center"})
                    }
                },400)
                this.setState({historyShow:!this.state.historyShow,loadButton:false})
            });
        }
    }

    render() {

        const { userRights: { ROAD_PROJECT_LIST,ROAD_QUERY}, noDataAvailable,  exportQrySearchResult: { loading: downloading } } = this.props;
        const { projectData, isDataLoading, projectDatalog,editCard, showInPopup, m_company_id} = this.props;

        const highLight=ROAD_PROJECT_LIST.ALLOW_SHOW_LAST_UPDATED?JSON.parse(projectDatalog[0]?.log_detail!==undefined?projectDatalog[0]?.log_detail:'[]'):[]
        projectData.company_m_company_id=highLight.filter((e)=>e.id==="company_m_company_id")
        var highLightValues=[]

        const heightData=highLight.filter((e)=>Object.keys(projectData).includes(e.id))
        heightData.map((e)=>{
            highLightValues.push(...Object.keys(projectData).filter(a=>a===e.id))
        })
        let rname = "road"
        const window_url = window.location.href;

        let showInpopup = false;
        if (!_.isNil(showInPopup) && showInPopup == true) {

            if (window.outerWidth >= 1200 && window.outerWidth <= 1440) {
                showInpopup = true;
            }
        }

        return (
            <Fragment>

                {isDataLoading ? (<ProjectViewLoader  />) :

                    (<>
                        <div className='row'>
                            <div className={`${this.projectId>0&& this.state.mapLoading&& (this.state.stretch.length>0 || this.state.tollMarker.length>0)&& !window.location.pathname.includes("/rdqry") ? 'col-7 pr-2' : 'col-12 pt-2'}`}>
                            <ProjectsView.ProjectInfoCard access={ROAD_PROJECT_LIST} window_url={window_url} highLightValues={highLightValues} heightData={heightData} {...this.props} viewFinanceData={this.viewFinanceData} sliderOpenDev={this.sliderOpenDev} sliderOpenSPV={this.sliderOpenSPV}  groupSlider={this.groupSlider} {...this.state} closeFinanceData={this.closeFinanceData} />
                            {/* <ProjectsView.CompanyInfoCard   highLightValues={highLightValues} /> */}
                            </div>
                          
                            {this.projectId>0&& this.state.mapLoading&& (this.state.stretch.length>0 || this.state.tollMarker.length>0)&& !window.location.pathname.includes("/rdqry")&&
                            <div className='col-5 pl-2'><div className='detail-map' ref={this.props.refMap}><MapView projectId={this.projectId} props={this.props} state={this.state} /></div> </div>
                            }
                            {/* {this.state.isOpenFinance ? (
                                <Col sm={12} className="mb-3">
                                <Card>
                                <Fragment>
                                    <div className="mt-2 mb-3"></div>
                                    <div className={cx("finance-card-container", { "show-in-popup": showInpopup })}>
                                        <Finance companyId={this.state.m_company_id} isViewCompanyDetails={false} isViewMode={true} />
                                    </div>
                                </Fragment>
                                </Card>
                                </Col>
                            ) : null} */}
                        </div>

                        <Card className="card-list-box project-view-details-card project-list-card">
                        <CardHeader>
                            <div className='d-flex justify-content-between'>
                                    <span className='text-dark title'>Project Name</span>
                                </div>
                            </CardHeader>
                            <CardBody className="pt-0 pb-2">
                            <div className='project-list-card mb-2'>
                                <span>{projectData?.road_project_name ? projectData?.road_project_name : '-'}</span>
                            </div>
                            </CardBody>
                        </Card>
                        
                        {
                            +projectData.m_project_status_id != PROJECT_STATUS_UNDER_BIDDING ? (
                                <Fragment>
                                    <Row>
                                        <Col md={12} className="">
                                            <ProjectsView.BiddingInfoCard  highLightValues={highLightValues}  heightData={heightData} {...this.props} />
                                        </Col>
                                        <Col md={7} className="pr-2">
                                        {
                                            <ProjectsView.RoadToll {...this.props} {...this.state} expandToggle={this.expandToggle} />
                                        }
                                            <ProjectsView.Upload projectId={this.projectId} state={this.state} ROAD_PROJECT_LIST={ROAD_PROJECT_LIST}/>
                                            <ProjectsView.BankInfoCard {...this.props}  highLightValues={highLightValues}  heightData={heightData} />
                                        </Col>
                                        <Col md={5} className="">
                                            <ProjectsView.PCODInfoCard {...this.props} highLightValues={highLightValues} heightData={heightData}/>
                                            <ProjectsView.MemberInfoCard {...this.props} highLightValues={highLightValues} heightData={heightData}/>
                                        </Col>
                                    </Row>
                                                                           
                                      {
                                        ROAD_PROJECT_LIST.ALLOW_EDIT && this.props.projectData.log_count>0&&<div className='text-end'>
                                        {
                                            !this.state.loadButton&& <></>
                                            // <Button size="sm" className='mt-3' loading={this.state.loadButton ? this.state.loadButton : undefined} color="primary" id="AddEntryTooltip20"  onClick={()=>this.openHistory()}> {!this.state.historyShow?"Show History":"Hide History"}</Button>
                                        }

                                        {
                                            this.state.loadButton && <Spinner></Spinner>
                                        }
                                      </div>
                                      }


                                    {ROAD_PROJECT_LIST.ALLOW_EDIT && projectDatalog.length > 0 && editCard === true &&ROAD_PROJECT_LIST.ALLOW_UPDATE_LOG_VIEW ?
                                       <>


                                       {/* {
                                       this.state.historyShow&& <div ref={this.history}><ProjectsView.EditInfoCard {...this.props}  {...this.state} /></div>
                                       } */}

                                       </> : ""
                                    }

{this.state.isOpenFinance ? (
                                <Col sm={12} className="mb-3">
                                <Card>
                                <Fragment>
                                    <div className="mt-2 mb-3"></div>
                                   
                                </Fragment>
                                </Card>
                                </Col>
                            ) : null}

                                    {window_url.includes("rdprojectsview") &&
                                        <div className={(this.state.sliderShow === true || this.state.isOpenFinance === true) ? 'slider-bg' : 'slider-bg-close'}>
                                            <div className={(this.state.sliderShow === true || this.state.isOpenFinance === true) ? 'company-slide-drawer' : 'company-slide-drawer-close'}>
                                                <Button onClick={(e) => { e.preventDefault(); this.closeSlider(); }} className="close-page-drawer-btn btn btn-sm close-btn-slider" id="ClosePageDrawerTooltip">
                                                    <div className={cx("navbar-toggler hamburger hamburger--elastic", { 'is-active': true })}>
                                                        <span className="hamburger-box">
                                                            <span className="hamburger-inner" />
                                                        </span>
                                                    </div>
                                                </Button>
                                                <UncontrolledTooltip target="ClosePageDrawerTooltip" placement="left">
                                                    Close drawer
                                                </UncontrolledTooltip>
                                                <div>
                                                    {this.state.sliderShow === true && 
                                                    <AvailableProject projectData={this.state.available} projectAllData={this.state.available_data} ctype={this.state.ctype} close={this.closeSlider} company={this.state.company} rname={rname}  downloadExcel={this.resAvailDataExportEvent}/> }
                                                    {this.state.isOpenFinance === true && 
                                                    <div className='p-3'>
                                                        <div className={cx("finance-card-container", { "show-in-popup": showInpopup })}>
                                                            <Finance companyId={this.state.m_company_id} isViewCompanyDetails={true} isViewMode={true} />
                                                        </div>
                                                    </div>}
                                                </div>
                                            </div>
                                        </div>}
                                </Fragment>
                            ) : null
                        }
                    </>
                    )
                }
                                    <AjaxLoader isOpen={this.state.isSlider} pageLoader={true} loaderText={'Fetching Details...'} altText={'Loadin'} toggle={() => { }} />
                                    <AjaxLoader isOpen={downloading} imgSrc='/assets/images/xls-download-blue.gif' altText={'download excel gif image'} toggle={() => { }} />


            </Fragment>
        );
    }

}
const mapStateToProps = (state, prevState) => {
    return {
        isDataLoading: state.Road.projectRowData.loading || false,
        projectData: state.Road.projectRowData.data || {},
        projectDatalog: state.Road.projectRowDatalog.data || {},
        isDataLoadingErr: state.Road.projectRowData.error || false,
        isDataLoadingErrMsg: state.Road.projectRowData.msg || '',
        userRights: _.has(state.Authentication, 'user.action') ? state.Authentication.user.action : {},
        exportQrySearchResult: state.Road.exportQrySearchResult || {}

    };
};
const mapDispatchToProps = (dispatch) => ({
    getProjectDetails: (projectid) => dispatch(fnRoadProjectActions.getProjectDetails(projectid)),
    getRoadProjectDetailsLog: (projectid) => dispatch(fnRoadProjectActions.getRoadProjectDetailsLog(projectid)),
    downloadExcelFileData: (data, filename) => dispatch(fnRoadProjectActions.downloadExcelFileData(data, filename))

});
export default connect(mapStateToProps, mapDispatchToProps)(ProjectsView);